//export const api = process.env.REACT_APP_API;
export const api = 'https://apifin.4test.info/api/v1/'  //staging
// export const api = 'https://api.finolexsuperplumber.com/api/v1/'  // prod
// export const api = 'http://localhost:8081/api/v1/'  // local

//process.env.REACT_APP_API;

export const av = process.env.REACT_APP_av;
export const pt = process.env.REACT_APP_pt;
export const did = process.env.REACT_APP_did;
export const country = process.env.REACT_APP_country;
export const dp = process.env.REACT_APP_dp;
