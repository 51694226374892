import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import ReactDragListView from "react-drag-listview";

class CreateCompany extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      pan: this.props.resp ? this.props.resp.data.output.pan : "",
      logo: this.props.resp ? this.props.resp.data.output.logo : "",
      coi: this.props.resp ? this.props.resp.data.output.coi : "",
      yearIncorporation: this.props.resp ? this.props.resp.data.output.yearIncorporation : "",
      industry: this.props.resp ? this.props.resp.data.output.industry : "",
      brandTagline: this.props.resp ? this.props.resp.data.output.brandTagline : "",
      file: "",
      facebook: this.props.resp ? this.props.resp.data.output.socialModel.facebook : "",
      twitter: this.props.resp ? this.props.resp.data.output.socialModel.twitter : "",
      pinterest: this.props.resp ? this.props.resp.data.output.socialModel.pinterest : "",
      linkedin: this.props.resp ? this.props.resp.data.output.socialModel.linkedin : "",
      instagram: this.props.resp ? this.props.resp.data.output.socialModel.instagram : "",

      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      industries: [],
      create: 1,
    };

  }

  componentDidMount() {
    const configurationsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "master/config?name=INDUSTRIES&exact=true&sortBy=" +
          this.state.sortBy +
          "&limit=" +
          this.state.limit +
          "&page=" +
          this.state.page;
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201) {
              this.setState({
                industries: resp.data.output.results[0].value.split(','),
                //totalResults: resp.data.output.totalResults,
              });
            }  else if (resp.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    configurationsRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  yearIncorporationHandleChange = (event) => {
    this.setState({ yearIncorporation: event.target.value });
  };
  logoHandleChange = (event) => {
    this.setState({ logo: event.target.value });
  };
  brandTaglineHandleChange = (event) => {
    this.setState({ brandTagline: event.target.value });
  };
  panHandleChange = (event) => {
    this.setState({ pan: event.target.value });
  };
  coiHandleChange = (event) => {
    this.setState({ coi: event.target.value });
  };
  industryHandleChange = (event) => {
    this.setState({ industry: event.target.value });
  };




  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "company/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,
              "logo": this.state.logo,
              "brandTagline": this.state.brandTagline,
              "yearIncorporation": this.state.yearIncorporation,
              "pan": this.state.pan,
              "coi": this.state.coi,
              "industry": this.state.industry,
              //"contacts": this.state.contacts,
              "socialModel": {
                "twitter": this.state.twitter,
                "linkedin": this.state.linkedin,
                "facebook": this.state.facebook,
                "instagram": this.state.instagram,
                "pinterest": this.state.pinterest,
              }
              // "addresses": this.state.addresses,
              // "locations": this.state.locations

            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Company location Updated");
              this.setState({ create: 0 });
            }  else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            // localStorage.clear();
            // window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "company",
            {
              "name": this.state.name,
              "logo": this.state.logo,
              "brandTagline": this.state.brandTagline,
              "yearIncorporation": this.state.yearIncorporation,
              "pan": this.state.pan,
              "coi": this.state.coi,
              "industry": this.state.industry,
              //"contacts": this.state.contacts,
              "socialModel": {
                "twitter": this.state.twitter,
                "linkedin": this.state.linkedin,
                "facebook": this.state.facebook,
                "instagram": this.state.instagram,
                "pinterest": this.state.pinterest,
              }

            },
            {
              headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"), platform: Constants.pt, appVersion: Constants.av },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Company Added");
              this.setState({ create: 0 });

            }  else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  onFileChange = (event) => {
    // Update the state
    this.setState({ file: event.target.files[0] }, this.updateLogo);
    // Create an object of formData

    // Request made to the backend api
  };
  updateLogo = () => {
    let token = localStorage.getItem("token");

    const formData = new FormData();

    // Update the formData object

    formData.append("file", this.state.file);
    formData.append("type", "company_logo");


    axios
      .post(
        Constants.api + "uploader/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: 0, program: 0
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {
          swal("Logo Added");
          this.setState({ logo: res.data.output.url });

        }  else if (res.status == 403) {
          localStorage.removeItem("userid");
          window.location.href = "/login";
        } else {
          // this.setState({alert:res.data.msg});
          swal("Something went wrong!")
        }
      })
  };
  render() {
    const handleLocationsChange = (i, e) => {
      let locations = this.state.locations;
      locations[i][e.target.name] = e.target.value;
      this.setState({ locations });
    }

    const addLocationsFields = () => {
      this.setState({
        locations: [
          ...this.state.locations,
          {
            "name": "",
            "gstNumber": "",
            "addressLine1": '',
            "addressLine2": '',
            "area": '',
            "city": '',
            "state": '',
            "pincode": '',
            "country": ''
          },
        ],
      });
    }

    const removeLocationsFields = (i) => {
      let locations = this.state.locations;
      locations.splice(i, 1);
      this.setState({ locations });
    }
    const handleFacebookChange = (e) => {
      this.setState({ facebook: e.target.value });
    }
    const handleTwitterChange = (e) => {
      this.setState({ twitter: e.target.value });
    }
    const handleInstagramChange = (e) => {
      this.setState({ instagram: e.target.value });
    }
    const handlePinterestChange = (e) => {
      this.setState({ pinterest: e.target.value });
    }
    const handleLinkedinChange = (e) => {
      this.setState({ linkedin: e.target.value });
    }



    const handleContactsChange = (i, e) => {
      let contacts = this.state.contacts;
      contacts[i][e.target.name] = e.target.value;
      this.setState({ contacts });
    }

    const addContactsFields = () => {
      this.setState({
        contacts: [
          ...this.state.contacts,
          {
            "title": '',
            "firstName": '',
            "secondName": '',
            "mobile": '',
            "email": '',
            "fax": '',
            "designation": ''
          },
        ],
      });
    }

    const removeContactsFields = (i) => {
      let contacts = this.state.contacts;
      contacts.splice(i, 1);
      this.setState({ contacts });
    }
    const handleAddressesChange = (i, e) => {
      let addresses = this.state.addresses;
      addresses[i][e.target.name] = e.target.value;
      this.setState({ addresses });
    }

    const addAddressesFields = () => {
      this.setState({
        addresses: [
          ...this.state.addresses,
          {
            "addressLine1": '',
            "addressLine2": '',
            "area": '',
            "city": '',
            "state": '',
            "pincode": '',
            "country": ''
          },
        ],
      });
    }

    const removeAddressesFields = (i) => {
      let addresses = this.state.addresses;
      addresses.splice(i, 1);
      this.setState({ addresses });
    }

    if (this.state.create == 0) {
      window.location = "/company";
    }

    let search = this.state.search;


    return (
      <>

        <main class="main-content max-height-vh-100 h-100">


          <div class="container-fluid my-3 py-3">
            <div class="row mb-5">

              <div class="col-lg-12 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-auto col-4">
                      <div class="avatar avatar-xl position-relative">
                        <img src={this.state.logo} alt="bruce" class="w-100 rounded-circle shadow-sm addborder" />
                      </div>
                      <div class="file-input-container">
                        <label for="file-input">
                          <span class="camera-icon">&#128247;</span>
                        </label>
                        <input id="file-input" style={{ display: "none" }} type="file" onChange={this.onFileChange}
                        />
                      </div>
                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                          {this.state.name}
                        </h5>
                        <p class="mb-0 font-weight-normal text-sm">
                          {this.state.brandTagline}

                        </p>
                      </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                      {/* <label class="form-check-label mb-0">
                        <small id="profileVisibility">
                          Switch to invisible
                        </small>
                      </label>
                      <div class="form-check form-switch ms-2 my-auto">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked onchange="visible()" />
                      </div> */}
                    </div>
                  </div>
                </div>
                <form >
                  <div class="card mt-4" id="basic-info">
                    <div class="card-header">
                      <h5>Company Info</h5>
                    </div>
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label> Name</label>
                            <input type="text" class="form-control" value={this.state.name} onChange={this.nameHandleChange} placeholder="Evolve" />
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label class="form-label mt-4 ms-0">Year of Incorporation</label>
                          <input type="text" class="form-control" value={this.state.yearIncorporation} onChange={this.yearIncorporationHandleChange} />

                        </div>
                        <div class="col-6">
                          <label class="form-label mt-4 ms-0">Brand Tagline</label>
                          <input type="text" class="form-control" value={this.state.brandTagline} onChange={this.brandTaglineHandleChange} />

                        </div>

                      </div>
                      <div class="row mt-4">
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>PAN</label>
                            <input type="text" class="form-control" value={this.state.pan} onChange={this.panHandleChange} placeholder="" />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>COI</label>
                            <input type="text" class="form-control" value={this.state.coi} onChange={this.coiHandleChange} placeholder="" />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 align-self-center">
                          <label class="form-label mt-4 ms-0">Industry</label>
                          <select class="form-control" name="choices-language" id="choices-language" value={this.state.industry} onChange={this.industryHandleChange} >
                            {this.state.industries.map(function (item, i) {
                              return (
                                <option value={item}>{item}</option>

                              )
                            })}

                          </select>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="card mt-4" id="password">
                    <div class="card-header">
                      <h5>Social Media Links</h5>
                    </div>
                    <div class="card-body pt-0">

                      <div class="row">

                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Facebook
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="facebook" value={this.state.facebook} class="form-control" placeholder="Link" onChange={(e) =>
                            handleFacebookChange(e)
                          } />
                        </div>

                      </div>

                      <div class="row">

                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Twitter
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="twitter" value={this.state.twitter} class="form-control" placeholder="Link" onChange={(e) =>
                            handleTwitterChange(e)
                          } />
                        </div>

                      </div>

                      <div class="row">

                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Pinterest
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="pinterest" value={this.state.pinterest} class="form-control" placeholder="Link" onChange={(e) =>
                            handlePinterestChange(e)
                          } />
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Linkedin
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="linkedin" value={this.state.linkedin} class="form-control" placeholder="Link" onChange={(e) =>
                            handleLinkedinChange(e)
                          } />
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Instagram
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="instagram" value={this.state.instagram} class="form-control" placeholder="Link" onChange={(e) =>
                            handleInstagramChange(e)
                          } />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-4" id="delete">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-sm-0 mb-4">
                        <div class="w-50">
                          <h5>Save Company</h5>
                        </div>
                        <div class="w-50 text-end">
                          <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.setState({ create: 0 })}>Cancel</button>
                          <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" onClick={this.handleFormSubmit} >Save Company</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </main >
      </>
    );
  }

}

export default CreateCompany;
