import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import ReactDragListView from "react-drag-listview";

class CreateCompanyContacts extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {

      firstName: this.props.resp ? this.props.resp.data.output.firstName : "",
      lastName: this.props.resp ? this.props.resp.data.output.secondName : "",
      title: this.props.resp ? this.props.resp.data.output.title : "",
      mobile: this.props.resp ? this.props.resp.data.output.mobile : "",
      email: this.props.resp ? this.props.resp.data.output.email : "",
      fax: this.props.resp ? this.props.resp.data.output.fax : "",
      designation: this.props.resp ? this.props.resp.data.output.designation : "",
      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      industries: [],
      create: 1,
    };

  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };


  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let id = parts.pop();
      id = parseInt(id);
      const token = localStorage.getItem("token");

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "company/companyContact/" + this.props.resp.data.output.id,

            {
              "title": this.state.title,
              "firstName": this.state.firstName,
              "secondName": this.state.lastName,
              "mobile": this.state.mobile,
              "email": this.state.email,
              "fax": this.state.fax,
              "designation": this.state.designation,

            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Company Updated");
              this.setState({ create: 0 });
            }  else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            localStorage.clear();
            window.contact = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "company/companyContact",
            {
              "title": this.state.title,
              "firstName": this.state.firstName,
              "secondName": this.state.lastName,
              "mobile": this.state.mobile,
              "email": this.state.email,
              "fax": this.state.fax,
              "designation": this.state.designation,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Company contact Added");
              window.location = "/company/manage/" + id + "#contacts"
            }  else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            // localStorage.clear();
            //window.contact = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {


    const handlefirstNameChange = (e) => {
      this.setState({ firstName: e.target.value });
    }
    const handlelastNameChange = (e) => {
      this.setState({ lastName: e.target.value });
    }

    const handletitleChange = (e) => {
      this.setState({ title: e.target.value });
    }
    const handlemobileChange = (e) => {
      this.setState({ mobile: e.target.value });
    }
    const handleemailChange = (e) => {
      this.setState({ email: e.target.value });
    }
    const handlefaxChange = (e) => {
      this.setState({ fax: e.target.value });
    }
    const handledesignationChange = (e) => {
      this.setState({ designation: e.target.value });
    }
    let search = this.state.search;

    return (
      <>

        <main>
          <div class="container-fluid my-3 py-3">
            <div class="row mb-5">

              <div class="col-lg-12 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-auto col-4">

                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                          Company Contact
                        </h5>

                      </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                      {/* <label class="form-check-label mb-0">
                        <small id="profileVisibility">
                          Switch to invisible
                        </small>
                      </label>
                      <div class="form-check form-switch ms-2 my-auto">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked onchange="visible()" />
                      </div> */}
                    </div>
                  </div>
                </div>
                <form >
                  <div class="card mt-4" id="basic-info">
                    <div class="card-header">
                      <h5>Contact Info</h5>
                    </div>
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label> Title</label>
                            <input type="text" class="form-control" value={this.state.title} onChange={handletitleChange} placeholder="" />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label>First Name</label>
                            <input type="text" class="form-control" value={this.state.firstName} onChange={handlefirstNameChange} />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label>Last Name</label>
                            <input type="text" class="form-control" value={this.state.lastName} onChange={handlelastNameChange} placeholder="" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label> Mobile</label>
                            <input type="text" class="form-control" maxlength="10" value={this.state.mobile} onChange={handlemobileChange} placeholder="" />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label>Email</label>
                            <input type="text" class="form-control" value={this.state.email} onChange={handleemailChange} />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label>Fax</label>
                            <input type="text" class="form-control" value={this.state.fax} onChange={handlefaxChange} placeholder="" />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label>Designation</label>
                            <input type="text" class="form-control" value={this.state.designation} onChange={handledesignationChange} placeholder="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="card mt-4" id="delete">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-sm-0 mb-4">
                        <div class="w-50">
                          <h5>Save Company contact</h5>
                        </div>
                        <div class="w-50 text-end">
                          <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.setState({ create: 0 })}>Cancel</button>
                          <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" onClick={this.handleFormSubmit} >Save Company contact</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>


          </div>
        </main >
      </>
    );
  }

}

export default CreateCompanyContacts;
