import React, { Component } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { getAllByPlaceholderText } from "@testing-library/react";
// import SimpleReactValidator from "simple-react-validator";
// import Switch from "react-switch";
// import ReactDragListView from "react-drag-listview";

class CreateProgramManagers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : '',
      email: this.props.resp ? this.props.resp.data.output.email : '',
      mobile: this.props.resp ? this.props.resp.data.output.mobile : '',
      password: this.props.resp ? this.props.resp.data.output.password : '',
      mobileWhatsApp: this.props.resp ? this.props.resp.data.output.mobileWhatsApp : '',
      designation: this.props.resp ? this.props.resp.data.output.designation : '',
      doa: this.props.resp ? this.props.resp.data.output.doa : '',
      marital: this.props.resp ? this.props.resp.data.output.marital : '',
      sex: this.props.resp ? this.props.resp.data.output.sex : '',
      parentId: this.props.resp ? this.props.resp.data.output.parentId : '',

      userType: this.props.resp ? this.props.resp.data.output.userType : '',
      parentType: this.props.resp ? this.props.resp.data.output.parentType : '',
      userJobTitle: this.props.resp ? this.props.resp.data.output.userJobTitle : '',
      userRegion: this.props.resp ? this.props.resp.data.output.userRegion : '',
      userBranch: this.props.resp ? this.props.resp.data.output.userBranch : '',

      states: [],
      citys: [],
      districts: [],
      // selectedStates: [],
      // selectedCitys: [],
      // selectedDistricts: [],
      selectedStates: this.props.resp ? this.props.resp.data.output.sStateId.split(',') : [],
      selectedCitys: this.props.resp ? this.props.resp.data.output.sCityId.split(',') : [],
      selectedDistricts: this.props.resp ? this.props.resp.data.output.sDistrictId.split(',') : [],
      parents: [],
      regions: [],
      branches: [],
      areas: [],
      limit: 100000,
      totalResults: 0,
      sortBy: 'name',
      search: '',
      page: 1
    };
    this.validator = new SimpleReactValidator()
  }
  convertToIntegerArray = () => {
    const selectedStates = this.state.selectedStates.map(numStr => parseInt(numStr, 10));
    this.setState({ selectedStates });
    const selectedDistricts = this.state.selectedDistricts.map(numStr => parseInt(numStr, 10));
    this.setState({ selectedDistricts });
    const selectedCitys = this.state.selectedCitys.map(numStr => parseInt(numStr, 10));
    this.setState({ selectedCitys });
  };

  componentDidMount() {
    this.convertToIntegerArray();

    if (this.props.resp) {
      this.SALESRequest(this.props.resp.data.output.userType)
    }
    const StatesRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "master/geography/state?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "master/geography/state?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201 && resp.data.result != 'error') {
              console.log(resp.data.output.results);
              this.setState({
                states: resp.data.output.results,
              });
              console.log(this.state.states);
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {

            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    StatesRequest();
    const districtsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "master/geography/district?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "master/geography/district?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201 && resp.data.result != 'error') {
              console.log(resp.data.output.results);
              this.setState({
                districts: resp.data.output.results,
              });
              console.log(this.state.districts);
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {

            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    districtsRequest();
    const CityRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "master/geography/city?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "master/geography/city?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201 && resp.data.result != 'error') {
              // console.log(resp.data.output.results);
              this.setState({
                citys: resp.data.output.results,
              });
              // console.log(this.state.states);
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {

            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    CityRequest();
  }

  SALESRequest = (userType) => {
    let parentType = '';
    if (userType == 'SALES')
      parentType = 'SH';
    else if (userType == 'SH')
      parentType = 'RO';

    else parentType = '';

    this.setState({ parentType })
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let programid = parts.pop();
    programid = parseInt(programid);
    const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
    let compid = pathSegments[pathSegments.length - 2];
    try {
      const token = localStorage.getItem("token");
      let url = "";
      this.state.search != ""
        ? (url =
          "user/manage?" +
          "name=" +
          "state" +
          "&mobile=" +
          "state" +
          "&userType=" + parentType +
          "&sortBy=" +
          "name" +
          "&limit=" +
          "10000" +
          "&page=" +
          "1")
        : (url =
          "user/manage?sortBy=" +
          "name" +
          "&userType=" + parentType +
          "&limit=" +
          "10000" +
          "&page=" +
          "1");
      const resp = axios
        .get(Constants.api + url, {
          headers: {
            Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
            platform: Constants.pt
          },

        })
        .then((resp) => {
          //  SALESs array

          if (resp.status == 201 && resp.data.result != "error") {
            console.log(resp.data.output.results);
            this.setState({
              parents: resp.data.output.results,
            });
            console.log(this.state.members);
          } else if (resp.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
          }
        }).catch((err) => {
          // window.location = "/login"
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  regionsRequest() {
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let programid = parts.pop();
    programid = parseInt(programid);
    const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
    let compid = pathSegments[pathSegments.length - 2];
    const SALESRequest = async () => {

      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "program/child/region/region?" +
            "name=" +
            this.state.search +
            "&mobile=" +
            this.state.search +
            "&userType=SALES" +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "program/child/region/region?sortBy=" +
            this.state.sortBy +
            "&userType=SALES" +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: {
              Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
              platform: Constants.pt
            },

          })
          .then((resp) => {
            //  SALESs array

            if (resp.status == 201 && resp.data.result != "error") {
              console.log(resp.data.output.results);
              this.setState({
                regions: resp.data.output.results,
              });
              console.log(this.state.members);
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    SALESRequest();
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleRegionChange = (event) => {
    this.setState({ userRegion: event.target.value })
    this.getBranchesbyRegion(event.target.value)
  };
  handleuserTypeChange = (event) => {
    this.setState({ userType: event.target.value }, this.SALESRequest(event.target.value));


  }
  handleBranchChange = (event) => {
    this.setState({ userBranch: event.target.value })
    this.getAreabyBranch(event.target.value)
  };
  handleAreaChange = (event) => {
    this.setState({ userArea: event.target.value })
    this.getCitiesbyArea(event.target.value)
  };
  getBranchesbyRegion = (id) => {
    try {
      const token = localStorage.getItem("token");
      const deletedata = new FormData();
      deletedata.append("id", id); //append the values with key, value pair

      axios
        .get(
          Constants.api + "program/child/region/region/" + id,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
          deletedata
        )
        .then((resp) => {
          //  casetype array

          if (resp.status == 200) {
            this.setState({ create: 1, branches: resp.data.output.branches });
          } else if (resp.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
          }
          //movie array
        }).catch((err) => {
          // window.location = "/login"
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  getAreabyBranch = (id) => {
    try {
      const token = localStorage.getItem("token");
      const deletedata = new FormData();
      deletedata.append("id", id); //append the values with key, value pair

      axios
        .get(
          Constants.api + "program/child/region/branch/" + id,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
          deletedata
        )
        .then((resp) => {
          //  casetype array

          if (resp.status == 200) {
            this.setState({ create: 1, areas: resp.data.output.branches });
          } else if (resp.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
          }
          //movie array
        }).catch((err) => {
          // window.location = "/login"
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  getCitiesbyArea = (id) => {
    try {
      const token = localStorage.getItem("token");
      const deletedata = new FormData();
      deletedata.append("id", id); //append the values with key, value pair

      axios
        .get(
          Constants.api + "program/child/region/area/" + id,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
          deletedata
        )
        .then((resp) => {
          //  casetype array

          if (resp.status == 200) {
            this.setState({ create: 1, cities: resp.data.output.cities.split(',') });
          } else if (resp.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
          }
          //movie array
        }).catch((err) => {
          // window.location = "/login"
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  getCitiesbyArea = (id) => {
    try {
      const token = localStorage.getItem("token");
      const deletedata = new FormData();
      deletedata.append("id", id); //append the values with key, value pair

      axios
        .get(
          Constants.api + "program/child/region/area/" + id,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
          deletedata
        )
        .then((resp) => {
          //  casetype array

          if (resp.status == 200) {
            this.setState({ create: 1, cities: resp.data.output.cities.split(',') });
          } else if (resp.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
          }
          //movie array
        }).catch((err) => {
          // window.location = "/login"
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  handleNestedInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    const group = target.dataset.group;

    this.setState((prevState) => ({
      ...prevState,
      [group]: {
        ...prevState[group],
        [name]: value,
      },
    }));
  };
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };


  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2];
      if (this.props.resp) {
        // Get the last second segment

        try {

          axios
            .patch(
              Constants.api + "user/manage/" + this.props.resp.data.output.id,
              {
                "name": this.state.name,
                "email": this.state.email,
                "mobile": this.state.mobile,
                "password": this.state.password,
                "designation": this.state.designation,
                // "dob": this.state.dob,
                // "doa": this.state.doa,
                // "marital": this.state.marital,
                // "sex": this.state.sex,
                "userType": this.state.userType,
                "parentType": this.state.parentType,
                mVerify: true,
                "userJobTitle": this.state.userJobTitle,
                "parentId": this.state.parentId,
                sStateId: this.state.selectedStates.join(','),
                sCityId: this.state.selectedCitys.join(','),
                sDistrictId: this.state.selectedDistricts.join(','),

              }
              ,

              {
                headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                swal("Manager Updated");
                this.onCancelTrigger(0);
              } else if (res.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
                // this.setState({alert:res.data.msg});
                swal("Something went wrong!");
              }
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
      else {
        // Get the last second segment

        try {
          axios
            .post(
              Constants.api + "user/manage",
              {
                "name": this.state.name,
                "email": this.state.email,
                "mobile": this.state.mobile,
                "password": this.state.password,
                "designation": this.state.designation,
                // "dob": this.state.dob,
                // "doa": this.state.doa,
                // "marital": this.state.marital,
                // "sex": this.state.sex,
                "userType": this.state.userType,
                "userJobTitle": this.state.userJobTitle,
                "parentId": this.state.parentId,
                "parentType": this.state.parentType,
                mVerify: true,
                sStateId: this.state.selectedStates.join(','),
                sCityId: this.state.selectedCitys.join(','),
                sDistrictId: this.state.selectedDistricts.join(','),

              },

              {
                headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
              }
            )
            .then((res) => {
              if (res.status === 201) {
                swal("Manager Added");
                // this.setState({ create: 0 })
                this.onCancelTrigger(0);
              } else if (res.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
                // this.setState({alert:res.data.msg});
                swal("Something went wrong!");
              }
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  onCancelTrigger = (event) => {
    this.props.setViewMode(event);
    event.preventDefault();
  }
  //this.onCancelTrigger(0)

  render() {

    if (this.state.create === 0) {
      window.location.reload()
    }


    const handleStateChange = (stateId) => {

      let selectedStates = this.state.selectedStates;
      const index = selectedStates.indexOf(stateId);

      if (index == -1) {

        selectedStates.push(stateId)

      } else {

        selectedStates.splice(index, 1);

      }
      this.setState({
        selectedStates: selectedStates
      })
    };
    const handleDistrictChange = (districtId) => {
      let selectedDistricts = this.state.selectedDistricts;
      const index = selectedDistricts.indexOf(districtId);

      if (index == -1) {
        selectedDistricts.push(districtId)

      } else {
        selectedDistricts.splice(index, 1);

      }
      this.setState({
        selectedDistricts: selectedDistricts
      })
    };

    const handleCityChange = (cityid) => {
      let selectedCitys = this.state.selectedCitys;
      const index = selectedCitys.indexOf(cityid);

      if (index == -1) {

        selectedCitys.push(cityid)

      } else {
        selectedCitys.splice(index, 1);

        // let selectedCitys = selectedCitys.filter((item) => item !== cityid);

      }
      this.setState({
        selectedCitys: selectedCitys
      })
    };
    let selectedCitys = this.state.selectedCitys;
    let selectedStates = this.state.selectedStates;
    let selectedDistricts = this.state.selectedDistricts;

    //  let search = this.state.search;

    return (
      <>

        <main>
          <div class="container-fluid my-3 py-3">
            <div class="row mb-5">

              <div class="col-lg-12 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-auto col-4">

                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                          Program Manager
                        </h5>

                      </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">

                    </div>
                  </div>
                </div>
                <form >
                  <div class="card mt-4" id="basic-info">
                    <div class="row">
                      <div class="col-6">
                        <div class=" mx-4 input-group-outline my-2">
                          Name
                        </div>
                        <div class=" mx-4 input-group-outline my-2">
                          <input type="text" maxlength="30" name="name" value={this.state.name} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class=" mx-4 input-group-outline my-2">
                          Mobile
                        </div>
                        <div class=" mx-4 input-group-outline my-2">
                          <input type="text" maxlength="30" name="mobile" value={this.state.mobile} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>


                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class=" mx-4 input-group-outline my-2">
                          Email
                        </div>
                        <div class=" mx-4 input-group-outline my-2">
                          <input type="text" maxlength="30" name="email" value={this.state.email} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>



                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class=" mx-4 input-group-outline my-2">
                          User Type
                        </div>
                        <div class=" mx-4 input-group-outline my-2">
                          <select value={this.state.userType} name="userType" class="form-control" onChange={(e) =>
                            this.handleuserTypeChange(e)
                          } >
                            <option value="">Select</option>

                            <option value="SALES">SALES</option>
                            <option value="SH">SH</option>
                            <option value="RO">RO</option>
                            <option value="HO">HO</option>
                            <option value="MK">MK</option>
                            <option value="ADMIN">ADMIN</option>
                            <option value="RETAILER">RETAILER</option>

                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class=" mx-4 input-group-outline my-2">
                          Parent
                        </div>
                        <div class=" mx-4 input-group-outline my-2">
                          <select value={this.state.parentId} name="parentId" class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } >
                            {this.state.parents.map(function (item, i) {
                              return (
                                <>
                                  <option value={item.id}>{item.name}</option>
                                </>
                              )
                            })}
                          </select>
                        </div>
                      </div>


                    </div>

                  </div>
                  <div class="card mt-4" id="basic-info">

                    <div class="card-body pt-2 ">
                      <section class="blog-area pt182">
                        <small>States:</small>
                        {this.state.states.map((state) => (
                          <div key={state.id}>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectedStates.includes(String(state.id)) || selectedStates.includes(state.id)}
                                onClick={() => handleStateChange(state.id)}
                              />&nbsp;
                              {state.name}
                            </label>
                            {selectedStates.includes(String(state.id)) || selectedStates.includes(state.id) ? (
                              <>
                                <div class="mx-4 row">
                                  <small> Districts:   </small>
                                  {this.state.districts.map((district) => (
                                    state.id == district.state.id ? <div class="col-sm-3"> <label >
                                      <input
                                        type="checkbox"
                                        checked={selectedDistricts.includes(String(district.id)) || selectedDistricts.includes(district.id)}
                                        onChange={() => handleDistrictChange(district.id)}
                                      />&nbsp;
                                      {district.name}
                                    </label>

                                      {selectedDistricts.includes(String(district.id)) || selectedDistricts.includes(district.id) ? (
                                        <>

                                          <div class="mx-2 row">
                                            <small> Cities:</small>
                                            {this.state.citys.map((city) => (
                                              district.id == city.district.id ? <div class="col-sm-6"> <label >
                                                <input
                                                  type="checkbox"
                                                  checked={selectedCitys.includes(String(city.id)) || selectedCitys.includes(city.id)}
                                                  onClick={() => handleCityChange(city.id)}
                                                />&nbsp;
                                                {city.name}
                                              </label></div> : ''
                                            ))}
                                          </div>
                                        </>
                                      ) : ''}
                                    </div>

                                      : ''
                                  ))}
                                </div>

                              </>
                            ) : ''}

                          </div>
                        ))}<br />
                      </section>

                    </div>
                  </div>


                  <div class="card mt-4" id="delete">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-sm-0 mb-4">
                        <div class="w-50">
                          <h5>Save Manager</h5>
                        </div>
                        <div class="w-50 text-end">
                          <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.onCancelTrigger(0)}>Cancel</button>
                          <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" onClick={this.handleFormSubmit} >Save </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>


          </div>
        </main >
      </>
    );
  }

}

export default CreateProgramManagers;
