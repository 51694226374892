import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import ReactDragListView from "react-drag-listview";

class createcompanyPartners extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {

      partnerName: this.props.resp ? this.props.resp.data.output.name : "",
      partnerType: this.props.resp ? this.props.resp.data.output.partnerType : "",
      partneraddress1: this.props.resp ? this.props.resp.data.output.addressModel.address1 : "",
      partneraddress2: this.props.resp ? this.props.resp.data.output.addressModel.address2 : "",
      partneraddress3: this.props.resp ? this.props.resp.data.output.addressModel.address3 : "",
      partnercity: this.props.resp ? this.props.resp.data.output.addressModel.city : "",
      partnerstate: this.props.resp ? this.props.resp.data.output.addressModel.state : "",
      partnercountry: this.props.resp ? this.props.resp.data.output.addressModel.country : "",
      latitude: this.props.resp ? this.props.resp.data.output.latitude : "",
      longitude: this.props.resp ? this.props.resp.data.output.longitude : "",
      mobile: this.props.resp ? this.props.resp.data.output.mobile : "",
      email: this.props.resp ? this.props.resp.data.output.email : "",
      partnerdistrict: '',
      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      industries: [],
      states: [],
      cities: [],
      districts: [],
      create: 1,
    };

  }
  handleStateChange = (event) => {
    const selectedId = event.target.value

    if (selectedId === '0') {
      this.setState({ partnerstate: '', partnercity: '', partner: '' });
    } else {
      this.districtData(selectedId);
    }
  };
  handleDistrictChange = (event) => {
    const selectedId = event.target.value

    if (selectedId === '0') {
      this.setState({ partnerstate: '', partnercity: '', partner: '' });
    } else {
      this.cityData(selectedId);
    }
  };
  districtData = (stateid) => {
    let token = localStorage.getItem('token');

    try {
      axios.get(Constants.api + `master/geography/state/${stateid}`, {
        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
        }
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {

              this.setState({ districts: res.data.output.districts, partnerstate: res.data.output.id })

            } else {
              // swal({
              //     text: res.data.msg,
              //     timer: 2000,
              //     buttons: false,
              // });
            }
          } else {
            // swal({
            //     text: res.data.msg,
            //     timer: 2000,
            //     buttons: false,
            // });
          }
        })

    } catch (error) {
      console.log(error)
    }
  }
  cityData = (districtid) => {
    let token = localStorage.getItem('token');

    try {
      axios.get(Constants.api + `master/geography/district/${districtid}`, {
        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
        }
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {

              this.setState({ cities: res.data.output.cities, partnerdistrict: res.data.output.id })

            } else {
              // swal({
              //     text: res.data.msg,
              //     timer: 2000,
              //     buttons: false,
              // });
            }
          } else {
            // swal({
            //     text: res.data.msg,
            //     timer: 2000,
            //     buttons: false,
            // });
          }
        })

    } catch (error) {
      console.log(error)
    }
  }
  componentDidMount() {
    const StateRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "master/geography/state?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "master/geography/state?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201) {
              console.log(resp.data.output.results);
              this.setState({
                states: resp.data.output.results,
                totalResults: resp.data.output.totalResults,
              });
              console.log(this.state.states);
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    StateRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  yearIncorporationHandleChange = (event) => {
    this.setState({ yearIncorporation: event.target.value });
  };
  logoHandleChange = (event) => {
    this.setState({ logo: event.target.value });
  };
  brandTaglineHandleChange = (event) => {
    this.setState({ brandTagline: event.target.value });
  };
  panHandleChange = (event) => {
    this.setState({ pan: event.target.value });
  };
  coiHandleChange = (event) => {
    this.setState({ coi: event.target.value });
  };
  industryHandleChange = (event) => {
    this.setState({ industry: event.target.value });
  };




  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let id = parts.pop();
      id = parseInt(id);
      const token = localStorage.getItem("token");

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "company/companyPartner/" + this.props.resp.data.output.id,
            {
              "name": this.state.partnerName,
              "partnerType": this.state.partnerType,
              "mobile": this.state.mobile,
              "email": this.state.email,
              "addressModel": {
                "address1": this.state.partneraddress1,
                "address2": this.state.partneraddress2,
                "address3": this.state.partneraddress3,
                "city": this.state.partnercity,
                "state": this.state.partnerstate,
                "pincode": this.state.partnerpincode,
                "country": this.state.partnercountry

              },
              "latitude": this.state.latitude,
              "longitude": this.state.longitude,
            },
            // "addresses": this.state.addresses,
            // "locations": this.state.partners


            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Company Partner Updated");
              this.setState({ create: 0 });
            } else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            // localStorage.clear();
            // window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "company/companyPartner",
            {
              "name": this.state.partnerName,
              "partnerType": this.state.partnerType,
              "mobile": this.state.mobile,
              "email": this.state.email,
              "addressModel": {
                "address1": this.state.partneraddress1,
                "address2": this.state.partneraddress2,
                "address3": this.state.partneraddress3,
                "city": this.state.partnercity,
                "state": this.state.partnerstate,
                "pincode": this.state.partnerpincode,
                "country": this.state.partnercountry

              },
              "latitude": this.state.latitude,
              "longitude": this.state.longitude,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Company Partner Added");
              this.setState({ create: 0 });

            } else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            // localStorage.clear();
            //window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  render() {




    return (
      <>

        <main>
          <div class="container-fluid my-3 py-3">
            <div class="row mb-5">

              <div class="col-lg-12 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-auto col-4">

                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                          Company Partner
                        </h5>

                      </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                      {/* <label class="form-check-label mb-0">
                        <small id="profileVisibility">
                          Switch to invisible
                        </small>
                      </label>
                      <div class="form-check form-switch ms-2 my-auto">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked onchange="visible()" />
                      </div> */}
                    </div>
                  </div>
                </div>
                <form >
                  <div class="card mt-4" id="basic-info">
                    <div class="card-header">
                      <h5>Partner Info</h5>
                    </div>
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label> Name</label>
                            <input type="text" class="form-control" name="partnerName" value={this.state.partnerName} onChange={this.handleInputChange} />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>Mobile</label>
                            <input type="text" class="form-control" name="mobile" value={this.state.mobile} onChange={this.handleInputChange} />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label> Email</label>
                            <input type="text" class="form-control" name="email" value={this.state.email} onChange={this.handleInputChange} />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>Partner Type</label>
                            <input type="text" class="form-control" value={this.state.partnerType} name="partnerType" onChange={this.handleInputChange} />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label> Latitude</label>
                            <input type="text" class="form-control" name="latitude" value={this.state.latitude} onChange={this.handleInputChange} />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>Longitude</label>
                            <input type="text" class="form-control" value={this.state.longitude} name="longitude" onChange={this.handleInputChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card mt-4" id="password">
                    <div class="card-header">
                      <h5>Address</h5>
                    </div>
                    <div class="card-body pt-0">

                      <div class="row">

                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Address Line 1
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" maxlength="30" name="partneraddress1" value={this.state.partneraddress1} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>

                      </div>

                      <div class="row">

                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Address Line 2

                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" maxlength="30" name="partneraddress2" value={this.state.partneraddress2} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>

                      </div>

                      <div class="row">

                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Address Line 3
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" maxlength="30" name="partneraddress3" value={this.state.partneraddress3} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>


                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          State
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          {/* <input type="text" name="partnerstate" value={this.state.partnerstate} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } /> */}
                          <select name="partnerstate" className="form-control2" onChange={this.handleStateChange} value={this.state.partnerstate}>
                            <option value='0'>Select State</option>
                            {this.state.states.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.id}>{item.name}</option>
                                </>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          District
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">

                          <select name="partnerdistrict" className="form-control2" onChange={this.handleDistrictChange} value={this.state.partnerdistrict}>

                            <option value='0'>Select District</option>
                            {this.state.districts.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.id}>{item.name}</option>
                                </>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          City
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">

                          <select name="partnercity" className="form-control2" onChange={this.handleInputChange} value={this.state.partnercity}>

                            <option value='0'>Select City</option>
                            {this.state.cities.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.id}>{item.name}</option>
                                </>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Pincode
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="partnerpincode" maxlength="6" value={this.state.partnerpincode} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Country
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="partnercountry" value={this.state.partnercountry} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-4" id="delete">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-sm-0 mb-4">
                        <div class="w-50">
                          <h5>Save Company Partner</h5>
                        </div>
                        <div class="w-50 text-end">
                          <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.setState({ create: 0 })}>Cancel</button>
                          <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" onClick={this.handleFormSubmit} >Save Company Partner</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </main >
      </>
    );
  }

}

export default createcompanyPartners;
