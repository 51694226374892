import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Footer from "../../../footer/footer.component";

class CreateDistrict extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      state: this.props.resp ? this.props.resp.data.output.state.id : "",
      latitude: this.props.resp ? this.props.resp.data.output.latitude : "",
      longitude: this.props.resp ? this.props.resp.data.output.longitude : "",
      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",
      search: "",
      create: 1,
      states: []
    };
    // this.searchUpdated = this.searchUpdated.bind(this);
  }
  componentDidMount() {
    const statesRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "master/geography/state?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "master/geography/state?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201) {
              console.log(resp.data.output.results);
              this.setState({
                states: resp.data.output.results,
                totalResults: resp.data.output.totalResults,
              });
              console.log(this.state.states);
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    statesRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  stateHandleChange = (event) => {
    this.setState({ state: event.target.value });
  };
  latitudeHandleChange = (event) => {
    this.setState({ latitude: event.target.value });
  };
  longitudeHandleChange = (event) => {
    this.setState({ longitude: event.target.value });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "master/geography/district/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,

              "state": this.state.state,
              "latitude": this.state.latitude,
              "longitude": this.state.longitude

            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("District Updated");
              this.setState({ create: 0 });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "master/geography/district",
            {
              "name": this.state.name,

              "state": this.state.state,
              "latitude": this.state.latitude,
              "longitude": this.state.longitude

            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("District Added");

              this.setState({
                name: '',
                phonecode: '',
                isoCode: '',
                flag: '',
                currency: '',
                latitude: '',
                longitude: ''

              });
            } else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    if (this.state.create == 0) {
      window.location = "/masters/geography/districts";
    }

    let search = this.state.search;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const addedContent = [...that.state.addedContent];
        const item = addedContent.splice(fromIndex, 1)[0];
        addedContent.splice(toIndex, 0, item);
        that.setState({ addedContent });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };
    return (
      <>
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Districts</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">New District</li>
                </ol>
              </nav>
              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>

            </div>
          </nav>
          <div class="card mt-4" id="basic-info">
            <div class="card-header">
              <h5>District Info</h5>
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-md-4">
                    <label class="form-label mt-4">Name</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                    {this.validator.message(
                      "State Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                  <div class="col-md-4">
                    <label class="form-label mt-4">State</label>
                    <select class="form-control" name="country id=" value={this.state.state} onChange={this.stateHandleChange}>
                      <option value="">Select State</option>

                      {this.state.states.map(function (item, i) {
                        return (<>
                          <option value={item.id}>{item.name}</option>
                        </>)
                      })}


                    </select>
                    {this.validator.message(
                      "state",
                      this.state.state,
                      "required"
                    )}
                  </div>

                </div>

                <div class="row">

                </div>
                <div class="row">

                  <div class="col-md-4">
                    <label class="form-label mt-4">Latitude</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="latitude" value={this.state.latitude} onChange={this.latitudeHandleChange} />


                  </div>
                  <div class="col-md-4">
                    <label class="form-label mt-4">Longitude</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="longitude" value={this.state.longitude} onChange={this.longitudeHandleChange} />


                  </div>

                </div>

                <div class=" mt-4" id="delete">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                      <div class="w-50">
                        {/* <p>Save District</p> */}
                        {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                      </div>
                      <div class="w-50 text-end">
                        <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.setState({ create: 0 })}>Cancel</button>
                        <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </main>

      </>
    );
  }

}

export default CreateDistrict;
