import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import SearchInput from "react-search-input";
import ReactDragListView from "react-drag-listview";

class CreateIssueTypes extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      "nameHI": this.props.resp ? this.props.resp.data.output.nameHI : '',
      "nameGU": this.props.resp ? this.props.resp.data.output.nameGU : '',
      "nameTE": this.props.resp ? this.props.resp.data.output.nameTE : '',
      "nameTA": this.props.resp ? this.props.resp.data.output.nameTA : '',
      "nameML": this.props.resp ? this.props.resp.data.output.nameML : '',
      "nameKN": this.props.resp ? this.props.resp.data.output.nameKN : '',
      "nameOR": this.props.resp ? this.props.resp.data.output.nameOR : '',
      "nameBN": this.props.resp ? this.props.resp.data.output.nameBN : '',
      description: this.props.resp ? this.props.resp.data.output.description : "",
      active: this.props.resp ? this.props.resp.data.output.active : false,
      file: "",

      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      create: 1,
      categorys: [],

    };
    this.activeHandleChange = this.activeHandleChange.bind(this);

  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  activeHandleChange(checked) {
    this.setState({ active: checked });
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      let url = "";
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2]; // Get the last second segment

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "program/issueType/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,
              "nameHI": this.state.nameHI,
              "nameGU": this.state.nameGU,
              "nameTE": this.state.nameTE,
              "nameTA": this.state.nameTA,
              "nameML": this.state.nameML,
              "nameKN": this.state.nameKN,
              "nameOR": this.state.nameOR,
              "nameBN": this.state.nameBN,

              "description": this.state.description,

              "active": this.state.active,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Issue Updated");
              // this.setState({ create: 0 });
              this.onCancelTrigger(0);
            } else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "program/issueType",
            {
              "name": this.state.name,
              "nameHI": this.state.nameHI,
              "nameGU": this.state.nameGU,
              "nameTE": this.state.nameTE,
              "nameTA": this.state.nameTA,
              "nameML": this.state.nameML,
              "nameKN": this.state.nameKN,
              "nameOR": this.state.nameOR,
              "nameBN": this.state.nameBN,
              description: this.state.description,

              "active": this.state.active,

            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Issue  Added");
              this.onCancelTrigger(0);
              this.setState({
                name: '',
                url: '',
                active: ''

              });
            } else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  addCategory(category) {
    this.setState({ searchTerm: "" })
    if (
      this.state.category.indexOf(category) == -1

    ) {
      this.state.category.push(category);
      //    this.state.categorys.splice(this.state.categorys.indexOf(category), 1);
    }
    console.log(this.state.categorys);
  }
  removeCategory(category) {
    this.state.category.splice(
      this.state.category.indexOf(category),
      1
    );
  }
  onFileChange = (event) => {

    // Update the state
    this.setState({ file: event.target.files[0] }, this.uploadImage);
    // Create an object of formData

    // Request made to the backend api
  };
  uploadImage = () => {
    let token = localStorage.getItem("token");

    const formData = new FormData();

    // Update the formData object

    formData.append("file", this.state.file);
    formData.append("type", 'Banner');


    axios
      .post(
        Constants.api + "uploader/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: 0, program: 0
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {

          this.setState({ url: res.data.output.url });

        } else if (res.status == 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          // this.setState({alert:res.data.msg});
          swal("Something went wrong!");
        }
      })
  };
  searchUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api + "program/issueType?name=" +
          term +
          "&sortBy=" +
          this.state.sortBy +
          "&limit=10&page=" +
          this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.setState({ categorys: res.data.output.results });
            console.log(this.state.categorys);
          } else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          }
        });
      this.setState({ searchTerm: term });
    } else {
      this.setState({
        categorys: [],
      });
    }
  }
  onCancelTrigger = (event) => {
    // Call the parent callback function
    this.props.setViewMode(event);
    event.preventDefault();
  }

  render() {
    if (this.state.create == 0) {
      window.location.reload()
    }

    let search = this.state.search;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const category = [...that.state.category];
        const item = category.splice(fromIndex, 1)[0];
        category.splice(toIndex, 0, item);
        that.setState({ category });
      },
      nodeSelector: "li",
      handleSelector: ".move",
    };
    return (
      <>
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;"> Banners</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Add New</li>
                </ol>
              </nav>
              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>

            </div>
          </nav>
          <div class="card mt-4" id="basic-info">
            <div class="card-header">
              <div class="col-md-8">

              </div>
              <h5>Issue Type</h5>
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name Hindi</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="nameHI" value={this.state.nameHI} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name Kannada</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="KN" value={this.state.KN} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name Gujrati</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="nameGU" value={this.state.nameGU} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name Bengali</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="nameBN" value={this.state.nameBN} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name Malayalam</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="nameML" value={this.state.nameML} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name Oriya</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="nameOR" value={this.state.nameOR} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name Tamil</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="nameTA" value={this.state.nameTA} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name Telugu</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="nameTE" value={this.state.nameTE} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Name",
                      this.state.name,
                      "required"
                    )}
                  </div>


                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Description</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="description" value={this.state.description} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Description",
                      this.state.name,
                      "required"
                    )}
                  </div>

                </div>


                <div class="row">


                  <div class="col-md-4">
                    <label class="form-label mt-4">Active?</label>
                    <br />
                    <Switch class="form-control" onChange={this.activeHandleChange} checked={this.state.active} />
                  </div>

                </div>

                <div class=" mt-4" id="delete">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                      <div class="w-50">
                        {/* <p>Save Country</p> */}
                        {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                      </div>
                      <div class="w-50 text-end">
                        <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.onCancelTrigger(0)}>Cancel</button>
                        <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </main >

      </>
    );
  }

}

export default CreateIssueTypes;
