import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import ReactDragListView from "react-drag-listview";

class CreateProgramRedeem extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {

      product: this.props.resp ? this.props.resp.data.output.product.id : '',
      points: this.props.resp ? this.props.resp.data.output.points : '',
      active: this.props.resp ? this.props.resp.data.output.active : false,
      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",
      search: "",
      redeem: [],
      create: 1
    };
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };

  componentDidMount() {
    const ProductsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentURL = window.location.pathname;
        const parts = currentURL.split("/");
        let programid = parts.pop();
        programid = parseInt(programid);
        const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
        let compid = pathSegments[pathSegments.length - 2];
        let url = "";
        url = "program/child/product-balance";
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}`, appVersion: Constants.av, platform: Constants.pt, company: compid, program: programid },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201 && resp.data.result != "error") {
              console.log(resp.data.output);
              this.setState({
                redeem: resp.data.output,
              });
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    ProductsRequest();
  }
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2];
      if (this.props.resp) {

        try {

          axios
            .patch(
              Constants.api + "program/child/redeem-product/" + this.props.resp.data.output.id,
              {
                "points": this.state.points,
                "active": this.state.active,
                "product": {
                  id: parseInt(this.state.product)
                }
              },

              {
                headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
              }
            )
            .then((res) => {
              if (res.status == 200) {
                swal("Points Updated");
                this.onCancelTrigger(0);
              }  else if (res.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
                // this.setState({alert:res.data.msg});
                swal("Something went wrong!");
              }
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
      else {
        // Get the last second segment

        try {
          axios
            .post(
              Constants.api + "program/child/redeem-product",
              {
                "points": this.state.points,
                "active": this.state.active,
                "product": {
                  id: parseInt(this.state.product)
                }
              },

              {
                headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
              }
            )
            .then((res) => {
              if (res.status == 201) {
                swal("Points Added");
                // this.setState({ create: 0 })
                this.onCancelTrigger(0);
              }  else if (res.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
                // this.setState({alert:res.data.msg});
                swal("Something went wrong!");
              }
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  activeHandleChange = (event) => {
    this.setState({ active: !this.state.active });
  }

  handleProductChange = (event) => {
    if (this.props.resp)
      swal("Product can not be changed once added.")
    else
      this.setState({ product: event.target.value });
  }
  onCancelTrigger = (event) => {
    this.props.setViewMode(event);
    event.preventDefault();
  }

  render() {

    if (this.state.create == 0) {
      window.location.reload()
    }


    let search = this.state.search;

    return (
      <>

        <main>
          <div class="container-fluid my-3 py-3">
            <div class="row mb-5">

              <div class="col-lg-12 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-auto col-4">

                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                          Program Points
                        </h5>

                      </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">

                    </div>
                  </div>
                </div>
                <form >
                  <div class="card mt-4" id="basic-info">

                    <div class="card-body pt-2 ">
                      <div class="row">
                        <div class="col-4">
                          <div class="input-group col-sm-12 input-group-static ">
                            <label>Product</label>

                            {(this.props.resp) ?
                              <select class="form-control" name="product" value={this.state.product} onChange={this.handleProductChange} >
                                <option value="">{this.props.resp.data.output.product.name}</option>
                              </select>
                              :
                              <>
                                <select class="form-control" name="product" value={this.state.product} onChange={this.handleProductChange} >
                                  <option value="">Select Product</option>
                                  {this.state.redeem.map(function (item, i) {
                                    return (
                                      <option value={item.id} >{item.name}({item.skuDetail})</option>
                                    )
                                  })
                                  }
                                </select>
                              </>
                            }
                          </div>
                        </div>

                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label>Points</label>
                            <input type="text" class="form-control" value={this.state.points} onChange={this.handleInputChange} name="points" placeholder="" />
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <label class="form-label mt-4">Active?</label>
                        <br />
                        <Switch class="form-control" id="redeemactive" onChange={this.activeHandleChange} checked={this.state.active} />
                      </div>
                    </div>

                  </div>



                  <div class="card mt-4" id="delete">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-sm-0 mb-4">
                        <div class="w-50">
                          {/* <p>Save Country</p> */}
                          {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                        </div>
                        <div class="w-50 text-end">
                          <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.onCancelTrigger(0)}>Cancel</button>
                          <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" onClick={this.handleFormSubmit} >Save </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>


          </div>
        </main >
      </>
    );
  }

}

export default CreateProgramRedeem;
