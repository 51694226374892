import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import ReactDragListView from "react-drag-listview";
import { Editor } from "react-draft-wysiwyg";
//import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';


class CreateMasterProgram extends Component {
  constructor(props) {
    const content = 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.';

    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      description: this.props.resp ? this.props.resp.data.output.description : "",
      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",
      search: "",
      create: 1,

    };

  }

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };




  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let compid = parts.pop();
      compid = parseInt(compid);
      const token = localStorage.getItem("token");

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "program/master/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,
              "description": this.state.description

            },
            {
              headers: {
                Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                platform: Constants.pt,
                appVersion: Constants.av,
                company: compid, program: 0
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Master Program Updated");
              this.setState({ create: 0 });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "program/master",
            {
              "name": this.state.name,
              "description": this.state.description


            },
            {
              headers: {
                Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                platform: Constants.pt,
                appVersion: Constants.av,
                company: compid, program: 0
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Master Program Added");
              this.setState({ create: 0 });

            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    if (this.state.create == 0) {
      window.location = "/company";
    }
    return (
      <>
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav
            class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky"
            id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" onClick={() => this.setState({ create: 0 })}>Programs</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">New Master Program</li>
                </ol>
              </nav>
              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>
            </div>
          </nav>
          <div class="container-fluid py-4">
            <div class="row mt-4">
              <div class="col-lg-9 col-12 mx-auto position-relative">
                <div class="card">
                  <div class="card-header p-3 pt-2">
                    <div
                      class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                      <i class="material-icons opacity-10">event</i>
                    </div>
                    <h6 class="mb-0">New Master Program</h6>
                  </div>
                  <div class="card-body pt-2">
                    <div class="row">
                      <div class="input-group  col-sm-12 input-group-outline my-4">
                        <input type="text" class="form-control" name="name" placeholder="Master Program Name" onChange={this.nameHandleChange}
                          value={this.state.name}
                        />

                      </div>
                    </div>

                    <div class="row">
                      <div class="input-group  col-sm-12 input-group-outline my-4">
                        <textarea class="form-control " onChange={this.descriptionHandleChange} placeholder="Master Program Description">{this.state.description}</textarea>
                      </div>


                    </div>

                    <div class="d-flex justify-content-end mt-4">
                      <button type="button" name="button" class="btn btn-light m-0" onClick={() => this.setState({ create: 0 })}>Cancel</button>&nbsp;
                      <button type="button" name="button" class="btn bg-gradient-primary m-0 ms-2" onClick={this.handleFormSubmit}>Create Master Program</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div >
        </main >
      </>
    );
  }

}

export default CreateMasterProgram;
