import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Footer from "../../footer/footer.component";
import Switch from "react-switch";
import ReactDragListView from "react-drag-listview";

class CreateFields extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.name : "",
      fieldFor: this.props.resp ? this.props.resp.data.fieldFor : "",
      type: this.props.resp ? this.props.resp.data.flag : "",
      title: this.props.resp ? this.props.resp.data.title : "",
      type: this.props.resp ? this.props.resp.data.type : "",

      placeholder: this.props.resp ? this.props.resp.data.placeholder : "",
      minLength: this.props.resp ? this.props.resp.data.minLength : 0,
      maxLength: this.props.resp ? this.props.resp.data.maxLength : 0,
      fileType: this.props.resp ? this.props.resp.data.fileType : "",
      mandatory: this.props.resp ? this.props.resp.data.mandatory : false,
      active: this.props.resp ? this.props.resp.data.active : false,
      options: this.props.resp ? this.props.resp.data.options : [{
        "key": '',
        "value": '',
      }],

      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      create: 1,
    };
    this.mandatoryHandleChange = this.mandatoryHandleChange.bind(this);
    this.activeHandleChange = this.activeHandleChange.bind(this);

  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  titleHandleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  placeholderHandleChange = (event) => {
    this.setState({ placeholder: event.target.value });
  };
  minLengthHandleChange = (event) => {
    this.setState({ minLength: event.target.value });
  };
  maxLengthHandleChange = (event) => {
    this.setState({ maxLength: event.target.value });
  };
  typeHandleChange = (event) => {
    this.setState({ type: event.target.value });
  };
  fieldForHandleChange = (event) => {
    this.setState({ fieldFor: event.target.value });
  };

  mandatoryHandleChange(checked) {
    this.setState({ mandatory: checked });
  }
  activeHandleChange(checked) {
    this.setState({ active: checked });
  }
  fileTypeHandleChange = (event) => {
    this.setState({ fileType: event.target.value });
  };


  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "master/fields/" + this.props.resp.data.id,
            {
              "name": this.state.name,
              "fieldFor": this.state.fieldFor,
              "type": this.state.type,
              "title": this.state.title,
              "placeholder": this.state.placeholder,
              "options": this.state.options,
              "minLength": this.state.minLength,
              "maxLength": this.state.maxLength,
              "fileType": this.state.fileType,
              "mandatory": this.state.mandatory,
              "active": this.state.active

            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Field Updated");
              this.setState({ create: 0 });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "master/fields",
            {
              "name": this.state.name,
              "fieldFor": this.state.fieldFor,
              "type": this.state.type,
              "title": this.state.title,
              "placeholder": this.state.placeholder,
              "options": this.state.options,
              "minLength": this.state.minLength,
              "maxLength": this.state.maxLength,
              "fileType": this.state.fileType,
              "mandatory": this.state.mandatory,
              "active": this.state.active

            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Field Added");

              this.setState({
                "name": '',
                "fieldFor": '',
                "type": '',
                "title": '',
                "placeholder": '',
                "options": [
                  {
                    "key": '',
                    "value": '',
                  }
                ],
                "minLength": '',
                "maxLength": '',
                "fileType": '',
                "mandatory": '',
                "active": ''

              });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    const handleOptionChange = (i, e) => {
      let options = this.state.options;
      options[i][e.target.name] = e.target.value;
      this.setState({ options });
    }

    const addFormFields = () => {
      this.setState({
        options: [
          ...this.state.options,
          { key: "", value: "" },
        ],
      });
    }

    const removeFormFields = (i) => {
      let options = this.state.options;
      options.splice(i, 1);
      this.setState({ options });
    }

    if (this.state.create == 0) {
      window.location = "/masters/fields";
    }

    let search = this.state.search;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {


        const options = [...that.state.options];
        const item = options.splice(fromIndex, 1)[0];
        options.splice(toIndex, 0, item);
        console.log(options);
        //that.setState({ options });
      },
      nodeSelector: "span",
      handleSelector: ".arrow",
    };
    let mandatory = this.state.mandatory;
    let active = this.state.active;
    return (
      <>
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Fields</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">New Field</li>
                </ol>
              </nav>
              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>

            </div>
          </nav>
          <div class="card mt-4" id="basic-info">
            <div class="card-header">
              <h5>Field Info</h5>
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-md-4">
                    <label class="form-label mt-4">Name</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                    {this.validator.message(
                      "Field Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                  <div class="col-md-4">
                    <label class="form-label mt-4">Title</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="title" value={this.state.title} onChange={this.titleHandleChange} />
                    {this.validator.message(
                      "Field title",
                      this.state.title,
                      "required"
                    )}
                  </div>
                  <div class="col-md-4">
                    <label class="form-label mt-4">Placeholder</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="placeholder" value={this.state.placeholder} onChange={this.placeholderHandleChange} />
                    {this.validator.message(
                      "Field placeholder",
                      this.state.placeholder,
                      "required"
                    )}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label class="form-label mt-4">Type</label>
                    <select class="form-control" name="type" id="" value={this.state.type} onChange={this.typeHandleChange}>
                      <option value="">Select Type</option>
                      <option value="TEXT">TEXT</option>
                      <option value="NUMBER">NUMBER</option>
                      <option value="DATE">DATE</option>
                      <option value="TIME">TIME</option>
                      <option value="DATETIME">DATETIME</option>
                      <option value="TEXTAREA">TEXTAREA</option>
                      <option value="RADIO">RADIO</option>
                      <option value="CHECKBOX">CHECKBOX</option>
                      <option value="EMAIL">EMAIL</option>
                      <option value="PASSWORD">PASSWORD</option>
                      <option value="DROPDOWN">DROPDOWN</option>
                      <option value="SWITCH">SWITCH</option>
                      <option value="FILE">FILE</option>
                      <option value="TEL">TEL</option>
                    </select>
                    {this.validator.message(
                      "Field type",
                      this.state.type,
                      "required"
                    )}
                  </div>
                  {(this.state.type == "RADIO" || this.state.type == "CHECKBOX" || this.state.type == "DROPDOWN") ?
                    <>
                      <div class="col-md-8" style={{ backgorund: "aliceblue" }}>


                        <ReactDragListView {...dragProps}>

                          {this.state.options.map(function (item, i) {

                            return (<>
                              <span key={i}>
                                <div class="row arrow" >
                                  <div class="col-md-4">
                                    <label class="form-label mt-4">Key</label>
                                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="key" value={item.key || ""}
                                      onChange={(e) =>
                                        handleOptionChange(
                                          i,
                                          e
                                        )
                                      } />

                                  </div>

                                  <div class="col-md-4">
                                    <label class="form-label mt-4">Value</label>
                                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="value" value={item.value || ""}
                                      onChange={(e) =>
                                        handleOptionChange(
                                          i,
                                          e
                                        )
                                      } />

                                  </div>

                                  {i > 0 ? (
                                    <>
                                      <div
                                        class="col-md-3"
                                        onClick={() =>
                                          removeFormFields(i)
                                        }
                                      >
                                        <label class="form-label mt-4">          <i
                                          class="fa fa-times"
                                          aria-hidden="true"
                                        ></i>{" "}</label>


                                      </div>

                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </span>
                              <hr />
                            </>)

                          }
                          )}


                        </ReactDragListView>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm col-sm-3 "
                          onClick={() => addFormFields()}
                        >
                          Add More Options
                        </button>

                      </div>
                    </>

                    : ''}
                  {(this.state.type == "TEXT" || this.state.type == "NUMBER" || this.state.type == "PASSWORD" || this.state.type == "TEL") ?
                    <>
                      <div class="col-md-4">
                        <label class="form-label mt-4">Minlength</label>
                        <input class="form-control" id="choices-skills" type="text" placeholder="" name="minLength" value={this.state.minLength} onChange={this.minLengthHandleChange} />
                        {this.validator.message(
                          "Field minLength",
                          this.state.minLength,
                          "required"
                        )}
                      </div>
                      <div class="col-md-4">
                        <label class="form-label mt-4">Maxlength</label>
                        <input class="form-control" id="choices-skills" type="text" placeholder="" name="maxLength" value={this.state.maxLength} onChange={this.maxLengthHandleChange} />
                        {this.validator.message(
                          "Field maxLength",
                          this.state.maxLength,
                          "required"
                        )}
                      </div>
                    </> : ''
                  }
                  {this.state.type == 'FILE' ?
                    <div class="col-md-4">
                      <label class="form-label mt-4">File Type?</label>
                      <select class="form-control" name="fileType" id="" value={this.state.fileType} onChange={this.fileTypeHandleChange}>
                        <option value="">Select Type</option>
                        <option value="IMAGE">IMAGE</option>
                        <option value="VIDEO">VIDEO</option>
                        <option value="DOCUMENT">DOCUMENT</option>
                        <option value="AUDIO">AUDIO </option>
                      </select>
                      {this.validator.message(
                        "fileType",
                        this.state.fileType,
                        "required"
                      )}
                    </div> : ''}
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="form-label mt-4">Field For?</label>
                    <select class="form-control" name="type" id="" value={this.state.fieldFor} onChange={this.fieldForHandleChange}>
                      <option value=""> Select</option>
                      <option value="REGISTERATION">REGISTERATION</option>
                    </select>
                    {this.validator.message(
                      "Field fieldFor",
                      this.state.fieldFor,
                      "required"
                    )}
                  </div>
                  <div class="col-md-4">
                    <label class="form-label mt-4">Mandatory?</label>
                    <br />
                    <Switch class="form-control" onChange={this.mandatoryHandleChange} checked={mandatory} />
                  </div>
                  <div class="col-md-4">
                    <label class="form-label mt-4">Active?</label>
                    <br />
                    <Switch class="form-control" onChange={this.activeHandleChange} checked={active} />
                  </div>
                </div>
                <div class="row">

                </div>
                <div class=" mt-4" id="delete">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                      <div class="w-50">
                        {/* <p>Save Country</p> */}
                        {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                      </div>
                      <div class="w-50 text-end">
                        <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.setState({ create: 0 })}>Cancel</button>
                        <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </main>

      </>
    );
  }

}

export default CreateFields;
