import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import CreateMasterProgram from "./createmasterprogram.component";

class MasterProgram extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name",
    limit: 25,
    totalResults: 0,
    page: 1,
    programs: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    const programsRequest = async () => {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let id = parts.pop();
      id = parseInt(id);
      try {
        let url = "";
        this.state.search != ""
          ? (url =
            "program/master?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "program/master?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}`, company: id, program: 0, "Accept-Language": "en", appversion: Constants.av, platform: Constants.pt, },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201 && resp.data.result != "error") {
              // console.log(resp.data.output.results);
              this.setState({
                programs: resp.data.output.results,
                totalResults: resp.data.output.totalResults,
              });
              // console.log(this.state.programs);
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    programsRequest();
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }

  render() {
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "program/master/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "program/master/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 200) {
                this.componentDidMount();
              }  else if (resp.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    const programsRender = this.state.programs.map(function (item, i) {
      return (
        <>
          <div class="col-lg-4 col-md-6 mb-4 mt-2">
            <div class="card">
              <div class="card-body p-3">
                <div class="d-flex mt-n2">

                  <div class="ms-3 my-auto">
                    <a href={`/viewprogram/${item.appParams.company}/${item.id}`}><h6 class="mb-0">{item.name}</h6></a>
                    <div class="avatar-group">

                    </div>
                  </div>
                  <div class="ms-auto">
                    <div class="dropdown">
                      <button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v text-lg"></i>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink2">
                        <a class="dropdown-item" onClick={() => editItem(item.id)}>Edit</a>
                        <a class="dropdown-item" href={`/viewprogram/${item.appParams.company}/${item.id}`}>View</a>

                      </div>
                    </div>
                  </div>
                </div>
                <p class="text-sm mt-3"> {item.description}</p>

              </div>
            </div>
          </div>
        </>
      );
    });

    return (
      <>
        {
          this.state.create == 0 ? (
            <>
              <div class="main-content position-relative bg-gray-100 max-height-vh-100 h-100">
                <nav class="navbar navbar-main navbar-expand-lg bg-transparent shadow-none position-absolute px-4 w-100 z-index-2">
                  <div class="container-fluid py-1">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 ps-2 me-sm-6 me-5">
                        <li class="breadcrumb-item text-sm"><a class="text-white opacity-8" href="/company">Company</a></li>

                        <li class="breadcrumb-item text-sm text-white active" aria-current="page">Master Programs</li>
                      </ol>
                    </nav>
                    <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none">
                      <a href="javascript:;" class="nav-link text-white p-0">
                        <div class="sidenav-toggler-inner">
                          <i class="sidenav-toggler-line bg-white"></i>
                          <i class="sidenav-toggler-line bg-white"></i>
                          <i class="sidenav-toggler-line bg-white"></i>
                        </div>
                      </a>
                    </div>
                    <div class="collapse navbar-collapse me-md-0 me-sm-4 mt-sm-0 mt-2" id="navbar">
                      <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                        <div class="input-group input-group-dynamic mb-4">
                          <input class="form-control" placeholder="Search" type="text" onChange={this.searchHandleChange} />
                          <span class="input-group-text"><i class="fas fa-search" aria-hidden="true"></i></span>
                        </div>
                      </div>

                    </div>
                  </div>
                </nav>
                <div class="container-fluid px-2 px-md-4">
                  <div class="page-header min-height-200 border-radius-xl mt-4" >
                    <span class="mask bg-gradient-primary opacity-6"></span>
                  </div>
                  <div class="card card-body mx-3 mx-md-4 mt-n6">
                    <div class="row gx-4">
                      <div class="col-auto">
                        {/* <div class="avatar avatar-xl position-relative">

                          <img src="%PUBLIC_URL%/assets/img/apple-icon.png" alt="profile_image" class="w-100 border-radius-lg shadow-sm" />
                        </div> */}
                      </div>
                      <div class="col-auto my-auto">
                        <div class="h-100">
                          <h5 class="mb-1">
                            Master Programs
                          </h5>
                          {/* <p class="mb-0 font-weight-normal text-sm">
                          CEO / Co-Founder
                        </p> */}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="container-fluid py-4">
                  <section class="py-3">
                    <div class="row mb-4 mb-md-0">
                      <div class="col-md-8 me-auto my-auto text-left">
                        <h5>Programs</h5>
                      </div>
                      <div class="col-lg-4 col-md-12 my-auto text-end" >
                        <a href="#" class="btn bg-gradient-primary mb-2" onClick={() => this.setState({ create: 1 })}
                        > <i class="material-icons text-white position-relative text-md pe-2">add</i> Add New</a>
                      </div>
                    </div>
                    <div class="row mt-lg-4 mt-2">
                      {programsRender}
                    </div>
                  </section>

                </div>
              </div>
              <div class="fixed-plugin">
                <a class="fixed-plugin-button text-dark position-fixed px-3 py-2">
                  <i class="material-icons py-2">settings</i>
                </a>
                <div class="card shadow-lg">
                  <div class="card-header pb-0 pt-3">
                    <div class="float-start">
                      <h5 class="mt-3 mb-0">Material UI Configurator</h5>
                      <p>See our dashboard options.</p>
                    </div>
                    <div class="float-end mt-4">
                      <button class="btn btn-link text-dark p-0 fixed-plugin-close-button">
                        <i class="material-icons">clear</i>
                      </button>
                    </div>
                  </div>
                  <hr class="horizontal dark my-1" />
                  <div class="card-body pt-sm-3 pt-0">
                    <div>
                      <h6 class="mb-0">Sidebar Colors</h6>
                    </div>
                    <a href="javascript:void(0)" class="switch-trigger background-color">
                      <div class="badge-colors my-2 text-start">
                        <span class="badge filter bg-gradient-primary active" data-color="primary" onclick="sidebarColor(this)"></span>
                        <span class="badge filter bg-gradient-dark" data-color="dark" onclick="sidebarColor(this)"></span>
                        <span class="badge filter bg-gradient-info" data-color="info" onclick="sidebarColor(this)"></span>
                        <span class="badge filter bg-gradient-success" data-color="success" onclick="sidebarColor(this)"></span>
                        <span class="badge filter bg-gradient-warning" data-color="warning" onclick="sidebarColor(this)"></span>
                        <span class="badge filter bg-gradient-danger" data-color="danger" onclick="sidebarColor(this)"></span>
                      </div>
                    </a>
                    <div class="mt-3">
                      <h6 class="mb-0">Sidenav Type</h6>
                      <p class="text-sm">Choose between 2 different sidenav types.</p>
                    </div>
                    <div class="d-flex">
                      <button class="btn bg-gradient-dark px-3 mb-2 active" data-class="bg-gradient-dark" onclick="sidebarType(this)">Dark</button>
                      <button class="btn bg-gradient-dark px-3 mb-2 ms-2" data-class="bg-transparent" onclick="sidebarType(this)">Transparent</button>
                      <button class="btn bg-gradient-dark px-3 mb-2 ms-2" data-class="bg-white" onclick="sidebarType(this)">White</button>
                    </div>
                    <p class="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>
                    <div class="mt-3 d-flex">
                      <h6 class="mb-0">Navbar Fixed</h6>
                      <div class="form-check form-switch ps-0 ms-auto my-auto">
                        <input class="form-check-input mt-1 ms-auto" type="checkbox" id="navbarFixed" onclick="navbarFixed(this)" />
                      </div>
                    </div>
                    <hr class="horizontal dark my-3" />
                    <div class="mt-2 d-flex">
                      <h6 class="mb-0">Sidenav Mini</h6>
                      <div class="form-check form-switch ps-0 ms-auto my-auto">
                        <input class="form-check-input mt-1 ms-auto" type="checkbox" id="navbarMinimize" onclick="navbarMinimize(this)" />
                      </div>
                    </div>
                    <hr class="horizontal dark my-3" />
                    <div class="mt-2 d-flex">
                      <h6 class="mb-0">Light / Dark</h6>
                      <div class="form-check form-switch ps-0 ms-auto my-auto">
                        <input class="form-check-input mt-1 ms-auto" type="checkbox" id="dark-version" onclick="darkMode(this)" />
                      </div>
                    </div>
                    <hr class="horizontal dark my-sm-4" />
                    <a class="btn bg-gradient-primary w-100" href="https://www.creative-tim.com/product/material-dashboard-pro">Buy now</a>
                    <a class="btn bg-gradient-info w-100" href="https://www.creative-tim.com/product/material-dashboard">Free demo</a>
                    <a class="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/overview/material-dashboard">View documentation</a>
                    <div class="w-100 text-center">
                      <a class="github-button" href="https://github.com/creativetimofficial/material-dashboard" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star creativetimofficial/material-dashboard on GitHub">Star</a>
                      <h6 class="mt-3">Thank you for sharing!</h6>
                      <a href="https://twitter.com/intent/tweet?text=Check%20Material%20UI%20Dashboard%20PRO%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard-pro" class="btn btn-dark mb-0 me-2" target="_blank">
                        <i class="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
                      </a>
                      <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-dashboard-pro" class="btn btn-dark mb-0 me-2" target="_blank">
                        <i class="fab fa-facebook-square me-1" aria-hidden="true"></i> Share
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : this.state.resp ? (
            <CreateMasterProgram resp={this.state.resp} />
          ) : (
            <CreateMasterProgram />
          )
        }
      </>
    );
  }
}

export default MasterProgram;
