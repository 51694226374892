import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import SearchInput from "react-search-input";
import ReactDragListView from "react-drag-listview";

class CreateProgramRegions extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      states: [],
      citys: [],
      selectedCitys: this.props.resp ? this.props.resp.data.output.citiesList : [],
      search: '',
      sortBy: "name",
      limit: "100000",
      page: 1,
      selectedStates: this.props.resp ? this.props.resp.data.output.stateIdsList : [],
    };

  }
  componentDidMount() {
    const StatesRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "master/geography/state?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "master/geography/state?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201 && resp.data.result != 'error') {
              console.log(resp.data.output.results);
              this.setState({
                states: resp.data.output.results,
              });
              console.log(this.state.states);
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {

            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    StatesRequest();
    const CityRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "master/geography/city?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "master/geography/city?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201 && resp.data.result != 'error') {
              console.log(resp.data.output.results);
              this.setState({
                citys: resp.data.output.results,
              });
              console.log(this.state.states);
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {

            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    CityRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  citiesHandleChange = (event) => {
    this.setState({ cities: event.target.value });

  }
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2];
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "program/child/region/region/" + this.props.resp.data.output.id,
            {
              name: this.state.name,
              citiesList: this.state.selectedCitys,
              stateIdsList: this.state.selectedStates

            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Region Updated");
              this.setState({ create: 0 });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "program/child/region/region/",
            {
              name: this.state.name,
              citiesList: this.state.selectedCitys,
              stateIdsList: this.state.selectedStates
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Region Added");

              this.setState({ createTemplate: 0 })
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };


  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  render() {
    if (this.state.create == 0) {
      window.location.reload()
    }


    const handleStateChange = (event, stateId) => {
      let selectedStates = this.state.selectedStates;
      const index = selectedStates.indexOf(stateId);

      if (index == -1) {
        selectedStates.push(stateId)

      } else {
        selectedStates.splice(index, 1);
      }
      this.setState({
        selectedStates: selectedStates
      })
    };

    const handleCityChange = (event, cityName) => {
      let selectedCitys = this.state.selectedCitys;
      const index = selectedCitys.indexOf(cityName);

      if (index == -1) {
        selectedCitys.push(cityName)

      } else {
        alert("ddd")
        let selectedCitys = selectedCitys.filter((item) => item !== cityName);

      }
      this.setState({
        selectedCitys: selectedCitys
      })
    };
    let selectedCitys = this.state.selectedCitys;
    let selectedStates = this.state.selectedStates;
    return (
      <>
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;"> Program Region</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Add New</li>
                </ol>
              </nav>
              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>

            </div>
          </nav>
          <div class="card mt-4" id="basic-info">
            <div class="card-header">
              <div class="col-md-8">
              </div>
              <h5>Program Region</h5>
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                    {this.validator.message(
                      "Region Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">States</label>
                    {this.state.states.map((state) => (
                      <div key={state.id}>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedStates.includes(state.id)}
                            onChange={(event) => handleStateChange(event, state.id)}
                          />&nbsp;
                          {state.name}
                        </label>
                        {selectedStates.includes(state.id) && (
                          <div class="mx-6 row">
                            {this.state.citys.map((city) => (
                              state.id == city.state ? <div class="col-sm-3"> <label >
                                <input
                                  type="checkbox"
                                  checked={selectedCitys.includes(city.name)}
                                  onChange={(event) => handleCityChange(event, city.name)}
                                />&nbsp;
                                {city.name}
                              </label></div> : ''
                            ))}
                          </div>
                        )}
                      </div>
                    ))}

                  </div>
                </div>
                <div class=" mt-4" id="delete">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                      <div class="w-50">
                        {/* <p>Save State</p> */}
                        {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                      </div>
                      <div class="w-50 text-end">
                        <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </main >

      </>
    );
  }

}

export default CreateProgramRegions;
