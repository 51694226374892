import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import SearchInput from "react-search-input";
import ReactDragListView from "react-drag-listview";
// import { Navigate } from "react-router-dom";

class CreateProgramBanners extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      url: this.props.resp ? this.props.resp.data.output.url : "",
      active: this.props.resp ? this.props.resp.data.output.active : false,
      file: "",

      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      create: 1,
      categorys: [],

    };
    this.activeHandleChange = this.activeHandleChange.bind(this);

  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  activeHandleChange(checked) {
    this.setState({ active: checked });
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      let url = "";
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2]; // Get the last second segment

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "program/banner/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,
              "url": this.state.url,

              "active": this.state.active,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            console.log("res", res);
            if (res.status == 200) {
              swal("Product Updated");
              // this.setState({ create: 0 });              
              this.onCancelTrigger(0);
            } else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            console.log("err", err);
            // if (res.status == 403) {
            //   localStorage.removeItem("userid");
            //   window.location.href = "/login";
            // }
          });;
      } else {
        axios
          .post(
            Constants.api + "program/banner",
            {
              "name": this.state.name,
              "url": this.state.url,

              "active": this.state.active,

            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Product  Added");
              this.onCancelTrigger(0);

              this.setState({
                name: '',
                url: '',
                active: ''

              });
            } else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  addCategory(category) {
    this.setState({ searchTerm: "" })
    if (
      this.state.category.indexOf(category) == -1

    ) {
      this.state.category.push(category);
      //    this.state.categorys.splice(this.state.categorys.indexOf(category), 1);
    }
    console.log(this.state.categorys);
  }
  removeCategory(category) {
    this.state.category.splice(
      this.state.category.indexOf(category),
      1
    );
  }
  onFileChange = (event) => {

    // Update the state
    this.setState({ file: event.target.files[0] }, this.uploadImage);
    // Create an object of formData

    // Request made to the backend api
  };
  uploadImage = () => {
    let token = localStorage.getItem("token");

    const formData = new FormData();

    // Update the formData object

    formData.append("file", this.state.file);
    formData.append("type", 'Banner');


    axios
      .post(
        Constants.api + "uploader/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: 0, program: 0
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {

          this.setState({ url: res.data.output.url });

        }  else if (res.status == 403) {
          localStorage.removeItem("userid");
          window.location.href = "/login";
        } else {
          // this.setState({alert:res.data.msg});
          swal("Something went wrong!");
        }
      })
  };
  searchUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api + "program/banner?name=" +
          term +
          "&sortBy=" +
          this.state.sortBy +
          "&limit=10&page=" +
          this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.setState({ categorys: res.data.output.results });
            console.log(this.state.categorys);
          }
        });
      this.setState({ searchTerm: term });
    } else {
      this.setState({
        categorys: [],
      });
    }
  }
  onCancelTrigger = (event) => {
    this.props.setViewMode(event);
    event.preventDefault();
  }
  //this.onCancelTrigger(0)

  render() {
    if (this.state.create == 0) {
      window.location.reload()
    }

    let search = this.state.search;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const category = [...that.state.category];
        const item = category.splice(fromIndex, 1)[0];
        category.splice(toIndex, 0, item);
        that.setState({ category });
      },
      nodeSelector: "li",
      handleSelector: ".move",
    };
    return (
      <>
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;"> Banners</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Add New</li>
                </ol>
              </nav>
              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>

            </div>
          </nav>
          <div class="card mt-4" id="basic-info">
            <div class="card-header">
              <div class="col-md-8">
                <div class="avatar avatar-xl position-relative">
                  <img src={this.state.url} class="w-200 shadow-sm addborder" />
                </div>
                <div class="file-input-container">
                  <label for="file-input">
                    <span class="camera-icon">&#128247;</span>
                  </label>
                  <input name="image" type="file" onChange={this.onFileChange}
                  />
                </div>
              </div>
              <h5>Banner</h5>
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.handleInputChange} />
                    {this.validator.message(
                      "Banner Name",
                      this.state.name,
                      "required"
                    )}
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="form-label mt-4">Active?</label>
                    <br />
                    <Switch class="form-control" onChange={this.activeHandleChange} checked={this.state.active} />
                  </div>

                </div>

                <div class=" mt-4" id="delete">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                      <div class="w-50">
                        {/* <p>Save Country</p> */}
                        {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                      </div>
                      <div class="w-50 text-end">
                        <button
                          class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto"
                          type="button"
                          name="button"
                          onClick={() => {
                            // this.setState({ create: 0 })
                            this.onCancelTrigger(0);
                          }}
                        >Cancel</button>
                        <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </main >

      </>
    );
  }

}

export default CreateProgramBanners;
