import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import ReactDragListView from "react-drag-listview";

class CreateProgramTiers extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {

      name: this.props.resp ? this.props.resp.data.output.name : "",
      description: this.props.resp ? this.props.resp.data.output.description : "",
      threshold: this.props.resp ? this.props.resp.data.output.threshold : 0,
      increaseType: this.props.resp ? this.props.resp.data.output.increaseType : 0,
      increaseValue: this.props.resp ? this.props.resp.data.output.increaseValue : 0,
      pointValue: this.props.resp ? this.props.resp.data.output.pointValue : 0,
      tierType: this.props.resp ? this.props.resp.data.output.tierType : 0,

      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",
      search: "",
      industries: [],
      create: 1
    };
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };


  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2];
      if (this.props.resp) {
        // Get the last second segment

        try {

          axios
            .patch(
              Constants.api + "program/child/tier/" + this.props.resp.data.output.id,
              {
                "name": this.state.name,
                "description": this.state.description,
                "threshold": this.state.threshold,
                "increaseType": this.state.increaseType,
                "increaseValue": this.state.increaseValue,
                "tierType": this.state.tierType,
                "pointValue": this.state.pointValue
              },

              {
                headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
              }
            )
            .then((res) => {
              if (res.status == 200) {
                swal("Tier Updated");
                this.onCancelTrigger(0);
              }  else if (res.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
                // this.setState({alert:res.data.msg});
                swal("Something went wrong!");
              }
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
      else {
        // Get the last second segment

        try {
          axios
            .post(
              Constants.api + "program/child/tier",
              {
                "name": this.state.name,
                "description": this.state.description,
                "threshold": this.state.threshold,
                "increaseType": this.state.increaseType,
                "tierType": this.state.tierType,
                "pointValue": this.state.pointValue
              },

              {
                headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
              }
            )
            .then((res) => {
              if (res.status == 201) {
                swal("Tier Added");
                // this.setState({ create: 0 })
                this.onCancelTrigger(0);
              }  else if (res.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
                // this.setState({alert:res.data.msg});
                swal("Something went wrong!");
              }
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  onCancelTrigger = (event) => {
    this.props.setViewMode(event);
    event.preventDefault();
  }

  render() {

    if (this.state.create == 0) {
      window.location.reload()
    }

    const handlenameChange = (e) => {
      this.setState({ name: e.target.value });
    }
    const handledescriptionChange = (e) => {
      this.setState({ description: e.target.value });
    }
    const handleincreaseValueChange = (e) => {
      this.setState({ increaseValue: e.target.value });
    }
    const handleincreaseTypeChange = (e) => {
      this.setState({ increaseType: e.target.value });
    }
    const handletierTypeChange = (e) => {
      this.setState({ tierType: e.target.value });
    }
    const handlepointValueChange = (e) => {
      this.setState({ pointValue: e.target.value });
    }
    const handlethresholdChange = (e) => {
      this.setState({ threshold: e.target.value });
    }

    let search = this.state.search;

    return (
      <>

        <main>
          <div class="container-fluid my-3 py-3">
            <div class="row mb-5">

              <div class="col-lg-12 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-auto col-4">

                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                          Program Tiers
                        </h5>

                      </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                      {/* <label class="form-check-label mb-0">
                        <small id="profileVisibility">
                          Switch to invisible
                        </small>
                      </label>
                      <div class="form-check form-switch ms-2 my-auto">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked onchange="visible()" />
                      </div> */}
                    </div>
                  </div>
                </div>
                <form >
                  <div class="card mt-4" id="basic-info">

                    <div class="card-body pt-2 ">
                      <div class="row">
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label> Name</label>
                            <input type="text" class="form-control" value={this.state.name} onChange={handlenameChange} placeholder="" />
                          </div>
                        </div>
                        <div class="input-group col-sm-4 input-group-static mt-2">
                          <label> Tier Type</label>
                          <select class="form-control" name="tierType" value={this.state.tierType} onChange={handletierTypeChange}>
                            <option value="">Select Type</option>
                            <option value="ACCURAL">ACCURAL</option>
                            <option value="REDEEM">REDEEM</option>

                          </select>
                        </div>
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label>Description</label>
                            <input type="text" class="form-control" value={this.state.description} onChange={handledescriptionChange} />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <div class="input-group input-group-static">
                            <label>Threshold</label>
                            <input type="text" class="form-control" value={this.state.threshold} onChange={handlethresholdChange} placeholder="" />
                          </div>
                        </div>

                        {this.state.tierType == 'ACCURAL' ?
                          <>
                            <div class="col-4">
                              <div class="input-group input-group-static">
                                <label> Increase Value</label>
                                <input type="text" class="form-control" maxlength="10" value={this.state.increaseValue} onChange={handleincreaseValueChange} placeholder="" />
                              </div>
                            </div>
                            <div class="input-group col-sm-4 input-group-static mt-2">
                              <label> Increase Type</label>
                              <select class="form-control" name="increaseType" value={this.state.increaseType} onChange={handleincreaseTypeChange}>
                                <option value="">Select Type</option>
                                <option value="FIXED">FIXED</option>
                                <option value="PERCENT">PERCENT</option>

                              </select>


                            </div> </> : <div class="col-4">
                            <div class="input-group input-group-static">
                              <label> Point Value</label>
                              <input type="text" class="form-control" maxlength="10" value={this.state.pointValue} onChange={handlepointValueChange} placeholder="" />
                            </div>
                          </div>

                        }
                      </div>
                    </div>

                  </div>
                  <div class="card mt-4" id="delete">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-sm-0 mb-4">
                        <div class="w-50">
                          <h5>Save Tier</h5>
                        </div>
                        <div class="w-50 text-end">
                          <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.onCancelTrigger(0)}>Cancel</button>
                          <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" onClick={this.handleFormSubmit} >Save </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>


          </div>
        </main >
      </>
    );
  }

}

export default CreateProgramTiers;
