import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import CreateMasterProgram from "./createmasterprogram.component";
import { matchPath } from 'react-router';
import ReactDragListView from "react-drag-listview";
import CreateTemplates from "./createtemplates.component";
import ProgramTiers from "components/programTiers/programTiers.component";
import ProgramMembers from "components/programMembers/programMembers.component";
import ProgramInvoices from "components/programInvoices/programInvoices.component";
import ProgramMemberbySales from "components/programMemberbySales/programMembers.component";
import Products from "components/masters/cproduct/products/products.component";
import ProgramRedeem from "components/programRedeem/programRedeem.component";
import ProgramManagers from "components/programManagers/programManagers.component";
import Orders from "components/Orders/orders.component";
import { Switch } from "react-router-dom";
import ProgramBanners from "components/programBanners/programBanners.component";
import IssueTypes from "components/issueTypes/issueTypes.component";
import ProgramComms from "components/programCommunications/programComms.component";
import ProgramRegions from "components/programRegions/programRegions.component";
import ProgramBranch from "components/programBranches/programBranch.component";
import ProgramArea from "components/programAreas/programArea.component";
import ProgramTickets from "components/programTickets/programTickets.component";
import LoginReport from "components/reports/loginReport.component";
import EnrollmentReport from "components/reports/enrollmentReport.component";
import SalesQrTransactionReport from "components/reports/salesQrTransaction.component";
import RedemptionReport from "components/reports/redemptionReport";
import Testimonials from "components/testimonials/testimonials.component";
import MemberTds from "components/MemberTds/MemberTds.component";
class CreateChildProgram extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: this.props.resp ? this.props.resp.data.output.name : "",
    title: this.props.resp ? this.props.resp.data.output.title : "",
    tagline: this.props.resp ? this.props.resp.data.output.tagline : "",
    startDate: this.props.resp ? this.props.resp.data.output.startDate : "",
    endDate: this.props.resp ? this.props.resp.data.output.endDate : "",
    descriptionShort: this.props.resp ? this.props.resp.data.output.descriptionShort : "",
    descriptionLong: this.props.resp ? this.props.resp.data.output.descriptionLong : "",
    tnc: this.props.resp ? this.props.resp.data.output.tnc : "",
    privacy: this.props.resp ? this.props.resp.data.output.privacy : "",
    faqs: this.props.resp ? this.props.resp.data.output.faqs : "",
    careNumber: this.props.resp ? this.props.resp.data.output.careNumber : "",
    userVerification: this.props.resp ? this.props.resp.data.output.userVerification : "",
    userApproval: this.props.resp ? this.props.resp.data.output.userApproval : "",
    careEmail: this.props.resp ? this.props.resp.data.output.careEmail : "",
    careTiming: this.props.resp ? this.props.resp.data.output.careTiming : "",
    careWhatsApp: this.props.resp ? this.props.resp.data.output.careWhatsApp : "",
    twitter: this.props.resp ? this.props.resp.data.output.social.twitter : "",
    linkedin: this.props.resp ? this.props.resp.data.output.social.linkedin : "",
    facebook: this.props.resp ? this.props.resp.data.output.social.facebook : "",
    instagram: this.props.resp ? this.props.resp.data.output.social.instagram : "",
    pinterest: this.props.resp ? this.props.resp.data.output.social.pinterest : "",
    catelog: this.props.resp ? this.props.resp.data.output.catelog : "",
    manual: this.props.resp ? this.props.resp.data.output.manual : "",
    whatsNew: this.props.resp ? this.props.resp.data.output.whatsNew : "",
    contactHtml: this.props.resp ? this.props.resp.data.output.contactHtml : "",

    userBlock: this.props.resp ? this.props.resp.data.output.limit.userBlock : false,
    apply: this.props.resp ? this.props.resp.data.output.limit.apply : false,
    scanPerday: this.props.resp ? this.props.resp.data.output.limit.scanPerday : 0,
    scanPerMonth: this.props.resp ? this.props.resp.data.output.limit.scanPerMonth : 0,
    redeemPerDay: this.props.resp ? this.props.resp.data.output.limit.redeemPerDay : 0,
    redeemPerMonth: this.props.resp ? this.props.resp.data.output.limit.redeemPerMonth : 0,
    redeemPerWeek: this.props.resp ? this.props.resp.data.output.limit.redeemPerWeek : 0,

    smsVendor: this.props.resp ? this.props.resp.data.output.config.smsVendor : 0,
    smsURl: this.props.resp ? this.props.resp.data.output.config.smsURl : 0,

    pointValue: this.props.resp ? this.props.resp.data.output.config.pointValue : 0,
    tierType: this.props.resp ? this.props.resp.data.output.config.tierType : "",
    bonusWelcome: this.props.resp ? this.props.resp.data.output.config.bonusWelcome : 0,
    firstTransBonus: this.props.resp ? this.props.resp.data.output.config.firstTransBonus : 0,
    // bonusAnniversary: this.props.resp ? this.props.resp.data.config.bonusAnniversary : 0,
    //bonusBirthday: this.props.resp ? this.props.resp.data.output.config.bonusBirthday : 0,
    tiers: [],
    whatsnewfile: "",
    manualfile: "",
    catalogfile: "",
    redemptionProducts: [],
    tierName: "",
    tierDescription: "",
    tierThreshold: 0,
    increaseType: "",
    increaseValue: "",
    sortBy: "name",
    limit: 100000,
    totalResults: 0,
    page: 1,
    program: {},
    search: "",
    create: 0,
    createTemplate: 0,
    //  id: this.props.match.params.id,
    currentPage: "basic",
    childPrograms: [],
    fields: [],
    selectedFields: [],
    selectedProducts: [],
    captureType: this.props.resp ? this.props.resp.data.output.captureType : "",
    codeType: "",
    states: [],
    allproducts: [],
    // masterProgram: this.props.match.params.id,
    products: [{ productId: 0, productName: "", regionPoints: [{ name: "", points: 0 }] }],
    users: [],
    regions: [{ name: "", states: [], bonuses: { welcome: 0, birthday: 0, anniversary: 0, milestone: [{ milestonePoints: 0, points: 0 }], }, }],
    templates: [{
      "name": "WELCOME",
      "emailActive": true,
      "emailFrom": "admin@wemonde.com",
      "emailSubject": "Welcome to Loyalty",
      "emailCC": "sandeep.gaur@wemonde.com",
      "emailBCC": "anjali.nair@wemonde.com",
      "emailBody": "Welcome to loyalty Evolve",
      "smsActive": true,
      "smsBody": "Welcome to loyalty Evolve",
      "waActive": true,
      "waBody": "Welcome to loyalty Evolve",
      "notifyActive": true,
      "notifyBody": "Welcome to loyalty Evolve",
      "programId": "63ff5cdfd3a87c7ccb2be2eb",
      "active": true
    }]

  };


  saveProgramDetail = async () => {
    const token = localStorage.getItem("token");
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let programid = parts.pop();
    programid = parseInt(programid);
    if (this.props.resp) {
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2]; // Get the last second segment

      try {
        const token = localStorage.getItem("token");

        axios
          .patch(
            Constants.api + "program/child/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,
              "title": this.state.title,
              "tagline": this.state.tagline,
              "startDate": this.state.startDate,
              "endDate": this.state.endDate,
              "pointValue": this.state.pointValue,

              "descriptionShort": this.state.descriptionShort,
              "descriptionLong": this.state.descriptionLong,
              "tnc": this.state.tnc,
              "privacy": this.state.privacy,
              "faqs": this.state.faqs,
              "careNumber": this.state.careNumber,
              "careEmail": this.state.careEmail,
              "careTiming": this.state.careTiming,
              "careWhatsApp": this.state.careWhatsApp,
              "captureType": this.state.captureType,
              "userVerification": this.state.userVerification,
              "userApproval": this.state.userApproval,
              "contactHtml": this.state.contactHtml,
              "manual": this.state.manual,
              "catelog": this.state.catelog,
              "config": {
                "tierType": this.state.tierType,
                "bonusWelcome": this.state.bonusWelcome,
                "bonusAnniversary": this.state.bonusAnniversary,
                "bonusBirthday": this.state.bonusBirthday,
                "smsVendor": this.state.smsVendor,

                "smsURl": this.state.smsURl,
              },
              "limit": {
                "userBlock": this.state.userBlock,
                "apply": this.state.apply,
                "scanPerday": this.state.scanPerday,
                "scanPerMonth": this.state.scanPerMonth,
                "redeemPerDay": this.state.redeemPerDay,
                "redeemPerMonth": this.state.redeemPerMonth,
                "redeemperWeek": this.state.redeemPerWeek
              },
              "social": {
                "twitter": this.state.twitter,
                "linkedin": this.state.linkedin,
                "facebook": this.state.facebook,
                "instagram": this.state.instagram,
                "pinterest": this.state.pinterest
              },
              "masterProgram": programid
            },

            {
              headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Program Details Updated");
            } else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    }
    else {
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2]; // Get the last second segment

      try {
        const token = localStorage.getItem("token");
        axios
          .post(
            Constants.api + "program/child",
            {
              "name": this.state.name,
              "title": this.state.title,
              "tagline": this.state.tagline,
              "startDate": this.state.startDate,
              "endDate": this.state.endDate,
              "pointValue": this.state.pointValue,

              "descriptionShort": this.state.descriptionShort,
              "descriptionLong": this.state.descriptionLong,
              "tnc": this.state.tnc,
              "privacy": this.state.privacy,
              "faqs": this.state.faqs,
              "careNumber": this.state.careNumber,
              "careEmail": this.state.careEmail,
              "careTiming": this.state.careTiming,
              "captureType": this.state.captureType,
              "careWhatsApp": this.state.careWhatsApp,
              "userVerification": this.state.userVerification,
              "userApproval": this.state.userApproval,
              "contactHtml": this.state.contactHtml,
              "manual": this.state.manual,
              "catelog": this.state.catelog,
              "config": {
                "tierType": this.state.tierType,
                "bonusWelcome": this.state.bonusWelcome,
                "bonusAnniversary": this.state.bonusAnniversary,
                "bonusBirthday": this.state.bonusBirthday,
                "smsVendor": this.state.smsVendor,
                "smsURl": this.state.smsURl,
              },
              "limit": {
                "userBlock": this.state.userBlock,
                "apply": this.state.apply,
                "scanPerday": this.state.scanPerday,
                "scanPerMonth": this.state.scanPerMonth,
                "redeemPerDay": this.state.redeemPerDay,
                "redeemPerMonth": this.state.redeemPerMonth,
                "redeemperWeek": this.state.redeemPerWeek
              },
              "social": {
                "twitter": this.state.twitter,
                "linkedin": this.state.linkedin,
                "facebook": this.state.facebook,
                "instagram": this.state.instagram,
                "pinterest": this.state.pinterest
              },
              "masterProgram": programid
            },

            {
              headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Program Details Added");
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    }
  };

  saveRegistrationFields = async () => {
    try {
      const token = localStorage.getItem("token");
      axios
        .patch(
          Constants.api + "program/programChilds/" + this.state.id,
          {

            registration: this.state.selectedFields
          },

          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            swal("Registration fields Updated");
          } else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
            // this.setState({alert:res.data.msg});
            swal("Something went wrong!");
          }
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  saveTier = async () => {
    try {
      const token = localStorage.getItem("token");
      axios
        .patch(
          Constants.api + "program/programChilds/" + this.state.id,
          {

            tier: this.state.tiers
          },

          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            swal("Tiers Updated");
          } else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
            // this.setState({alert:res.data.msg});
            swal("Something went wrong!");
          }
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  saveProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      axios
        .patch(
          Constants.api + "program/programChilds/" + this.state.id,
          {
            products: this.state.selectedProducts
          },

          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            swal("Products Updated");
          } else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
            // this.setState({alert:res.data.msg});
            swal("Something went wrong!");
          }
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  saveCaptureType = async () => {
    try {
      const token = localStorage.getItem("token");
      axios
        .patch(
          Constants.api + "program/programChilds/" + this.state.id,
          {

            captureType: this.state.captureType,
            code: {
              codeType: this.state.codeType
            }
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            swal("Capture Type Updated");
          } else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
            // this.setState({alert:res.data.msg});
            swal("Something went wrong!");
          }
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  onCatalogChange = (event) => {


    // Update the state
    this.setState({ catalogfile: event.target.files[0] }, this.uploadCatalogue('catalog', event.target.files[0]));
    // Create an object of formData
    // Request made to the backend api
  };
  onManualChange = (event) => {

    // Update the state
    this.setState({ manualfile: event.target.files[0] }, this.uploadManual('manual'));
    // Create an object of formData

    // Request made to the backend api
  };
  onwhatsNewChange = (event) => {

    // Update the state
    this.setState({ whatsnewfile: event.target.files[0] }, this.uploadwhatsNew('whatsNew'));
    // Create an object of formData

    // Request made to the backend api
  };
  uploadCatalogue = async (name, file) => {
    let token = localStorage.getItem("token");

    const formData = new FormData();

    // Update the formData object

    formData.append("file", file);
    formData.append("type", name);


    await axios
      .post(
        Constants.api + "uploader/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: 0, program: 0
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {
          let n = name;
          alert(n);
          swal(n + " Added");
          this.setState({ catelog: res.data.output.url });

        } else if (res.status == 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          // this.setState({alert:res.data.msg});
          swal("Something went wrong!");
        }
      })
  };
  uploadManual = (name) => {
    let token = localStorage.getItem("token");

    const formData = new FormData();

    // Update the formData object

    formData.append("file", this.state.manualfile);
    formData.append("type", name);


    axios
      .post(
        Constants.api + "uploader/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: 0, program: 0
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {
          let n = name;
          swal(n + " Added");
          this.setState({ [n]: res.data.output.url });
          console.log(this.state.catalog)

        } else if (res.status == 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          // this.setState({alert:res.data.msg});
          swal("Something went wrong!");
        }
      })
  };
  uploadwhatsNew = (name) => {
    let token = localStorage.getItem("token");

    const formData = new FormData();

    // Update the formData object

    formData.append("file", this.state.whatsnewfile);
    formData.append("type", name);


    axios
      .post(
        Constants.api + "uploader/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: 0, program: 0
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {
          let n = name;
          swal(n + " Added");
          this.setState({ [n]: res.data.output.url });
          console.log(this.state.whatsnewfile)

        } else if (res.status == 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          // this.setState({alert:res.data.msg});
          swal("Something went wrong!");
        }
      })
  };
  saveRegion = async () => {
    try {
      const token = localStorage.getItem("token");
      axios
        .patch(
          Constants.api + "program/programChilds/" + this.state.id,
          {

            region: this.state.regions,

          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            swal("Region Updated");
          } else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
            // this.setState({alert:res.data.msg});
            swal("Something went wrong!");
          }
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  userApprovalHandleChange = (event) => {
    this.setState({ userApproval: event.target.value });
  };
  userVerificationHandleChange = (event) => {
    this.setState({ userVerification: event.target.value });
  };
  captureTypeHandleChange = (event) => {
    this.setState({ captureType: event.target.value });
  };
  codeTypeHandleChange = (event) => {
    this.setState({ codeType: event.target.value });
  };
  handlenameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  handletncChange = (event) => {
    this.setState({ tnc: event.target.value });
  };
  handletitleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  handlestartDateChange = (event) => {
    this.setState({ startDate: event.target.value });
  };
  handleendDateChange = (event) => {
    this.setState({ endDate: event.target.value });
  };
  handledescriptionShortChange = (event) => {
    this.setState({ descriptionShort: event.target.value });
  };
  handledescriptionLongChange = (event) => {
    this.setState({ descriptionLong: event.target.value });
  };
  handletaglineChange = (event) => {
    this.setState({ tagline: event.target.value });
  };
  handleprivacyChange = (event) => {
    this.setState({ privacy: event.target.value });
  };
  handlefaqsChange = (event) => {
    this.setState({ faqs: event.target.value });
  };
  handlecareNumberChange = (event) => {
    this.setState({ careNumber: event.target.value });
  };
  handlecarEmailChange = (event) => {
    this.setState({ carEmail: event.target.value });
  };
  handlecareTimingChange = (event) => {
    this.setState({ careTiming: event.target.value });
  };
  handlecareWhatsAppChange = (event) => {
    this.setState({ careWhatsApp: event.target.value });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  render() {
    const handleRegionChange = (i, e) => {
      let regions = this.state.regions;
      regions[i][e.target.name] = e.target.value;
      this.setState({ regions });
    }

    const handleTemplateChange = (i, e) => {
      let templates = this.state.templates;
      templates[i][e.target.name] = e.target.value;
      this.setState({ templates });
    }

    const addRegions = () => {
      this.setState({
        regions: [
          ...this.state.regions,
          { name: "", states: [], bonuses: { welcome: 0, birthday: 0, anniversary: 0, milestone: [{ milestonePoints: 0, points: 0 }], }, },],
      });
    }

    const removeRegions = (i) => {
      let regions = this.state.regions;
      regions.splice(i, 1);
      this.setState({ regions });
    }
    const addTiers = () => {
      this.setState({
        tiers: [
          ...this.state.tiers,
          { name: "", qualifyPoints: 0, bonusesPercent: 0 }]
      })
    }
    const removeTiers = (i) => {
      let tiers = this.state.tiers;
      tiers.splice(i, 1);
      this.setState({ tiers });
    }
    const editTemplate = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "master/templates/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ createTemplate: 1, resp: resp });
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteTemplate = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "master/templates/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 200) {
                this.componentDidMount();
              } else if (resp.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };
    const selectfield = (field) => {
      let selectedFields = this.state.selectedFields;

      if (selectedFields.indexOf(field) > -1)
        selectedFields.splice(selectedFields.indexOf(field), 1);
      else
        selectedFields.push(field);
      this.setState({ selectedFields })
    }
    const selectProduct = (product) => {
      let selectedProducts = this.state.selectedProducts;

      if (selectedProducts.indexOf(product) > -1)
        selectedProducts.splice(selectedProducts.indexOf(product), 1);
      else
        selectedProducts.push(product);
      this.setState({ selectedProducts })
    }
    //Dragging
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {

        const selectedFields = [...that.state.selectedFields];
        const item = selectedFields.splice(fromIndex, 1)[0];
        selectedFields.splice(toIndex, 0, item);
        console.log(selectedFields);
        that.setState({ selectedFields });
        const fields = [...that.state.fields];
        const item2 = fields.splice(fromIndex, 1)[0];
        fields.splice(toIndex, 0, item2);
        console.log(fields);
        that.setState({ fields });
      },
      nodeSelector: "li",
      handleSelector: ".movearrow",
    };

    const selectedFields = this.state.selectedFields;
    const selectedProducts = this.state.selectedProducts;

    const states = this.state.states;
    let programId = this.state.id;
    return (
      <>
        {this.state.create == 0 ? (
          <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
              <nav
                class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky"
                id="navbarBlur" data-scroll="true">
                <div class="container-fluid py-1 px-3">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                      <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" onClick={() => window.location.reload()}>Programs</a></li>
                      <li class="breadcrumb-item text-sm text-dark active" aria-current="page">{this.state.name}</li>
                    </ol>
                    {/* <h6 class="font-weight-bolder mb-0">New Project</h6> */}
                  </nav>

                  <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                    <a href="javascript:;" class="nav-link text-body p-0">
                      <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                      </div>
                    </a>
                  </div>

                </div>
              </nav>

              <div class="container-fluid py-4">
                <div class="row mt-4">
                  <div class="col-lg-3">
                    <div class="card position-sticky top-1">
                      <ul class="nav flex-column bg-white border-radius-lg p-1">
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'basic' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "basic" })}>
                            <i class="material-icons text-lg me-2">info</i>
                            <span class="text-sm">Basic Info</span>
                          </a>
                        </li>
                        {/* <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'bonuses' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "bonuses" })}>
                            <i class="material-icons text-lg me-2">star</i>
                            <span class="text-sm">Bonus</span>
                          </a>
                        </li> */}

                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'products' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "products" })}>
                            <i class="material-icons text-lg me-2">shopping_basket</i>
                            <span class="text-sm">Products</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'banners' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "banners" })}>
                            <i class="material-icons text-lg me-2">image</i>
                            <span class="text-sm">Banners</span>
                          </a>
                        </li>
                        {/* <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'regions' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "regions" })}>
                            <i class="material-icons text-lg me-2">public</i>
                            <span class="text-sm">Regions</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'branches' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "branches" })}>
                            <i class="material-icons text-lg me-2">call_split</i>
                            <span class="text-sm">Branches</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'areas' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "areas" })}>
                            <i class="material-icons text-lg me-2">place</i>
                            <span class="text-sm">Areas</span>
                          </a>
                        </li> */}
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'redemptionproducts' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "redemptionproducts" })}>
                            <i class="material-icons text-lg me-2">star</i>
                            <span class="text-sm">Redemption Products</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'issuetypes' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "issuetypes" })}>
                            <i class="material-icons text-lg me-2">report</i>
                            <span class="text-sm">Issue Types</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'tickets' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "tickets" })}>
                            <i class="material-icons text-lg me-2">star</i>
                            <span class="text-sm">Tickets</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'tiers' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "tiers" })}>
                            <i class="material-icons text-lg me-2">group_add</i>
                            <span class="text-sm">Tiers</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'users' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "users" })}>
                            <i class="material-icons text-lg me-2">person</i>
                            <span class="text-sm">Members</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'invoice' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "invoice" })}>
                            <i class="material-icons text-lg me-2">person</i>
                            <span class="text-sm">Invoice Approval</span>
                          </a>
                        </li>
                        {/* <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'usersbysales' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "usersbysales" })}>
                            <i class="material-icons text-lg me-2">person</i>
                            <span class="text-sm">Members Added by Sales</span>
                          </a>
                        </li> */}
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'managers' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "managers" })}>
                            <i class="material-icons text-lg me-2">account_circle</i>
                            <span class="text-sm">Managers</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'communications' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "communications" })}>
                            <i class="material-icons text-lg me-2">email</i>
                            <span class="text-sm">Communications</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'orders' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "orders" })}>
                            <i class="material-icons text-lg me-2">shopping_cart</i>
                            <span class="text-sm">Redeem Orders</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'tds' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "tds" })}>
                            <i class="material-icons text-lg me-2">report</i>
                            <span class="text-sm">TDS</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'loginreport' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "loginreport" })}>
                            <i class="material-icons text-lg me-2">report</i>
                            <span class="text-sm">Login Report</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'enrollmentreport' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "enrollmentreport" })}>
                            <i class="material-icons text-lg me-2">report</i>
                            <span class="text-sm">Enrollment Report</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'qrreport' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "qrreport" })}>
                            <i class="material-icons text-lg me-2">report</i>
                            <span class="text-sm">QR Transaction Report</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'redemptionreport' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "redemptionreport" })}>
                            <i class="material-icons text-lg ">report</i>
                            <span class="text-sm">Redemption Report</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'testimonials' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "testimonials" })}>
                            <i class="material-icons text-lg ">report</i>
                            <span class="text-sm">Testimonials</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {this.state.currentPage == 'basic' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <div class="card-header p-3 pt-2">
                            <div
                              class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                              <i class="material-icons opacity-10">info</i>
                            </div>
                            <h6 class="mb-0">{this.state.name}</h6>
                          </div>
                          <div class="card-body pt-2">
                            <div class="row">
                              <div class="input-group col-sm-6  input-group-static">
                                <label> Name</label>
                                <input type="text" class="form-control" name="name" value={this.state.name} onChange={this.handlenameChange} />

                              </div>
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> Capture Type</label>
                                <select class="form-control" name="captureType" value={this.state.captureType} onChange={this.captureTypeHandleChange}>
                                  <option value="">Select Type</option>
                                  <option value="CODE">CODE</option>
                                  <option value="INVOICE">INVOICE</option>
                                  <option value="QUANTITY">QUANTITY</option>
                                  <option value="CSV">CSV</option>
                                </select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> User Verfication</label>
                                <select class="form-control" name="userVerification" value={this.state.userVerification} onChange={this.userVerificationHandleChange}>
                                  <option value="">Select Type</option>
                                  <option value="NONE">NONE</option>
                                  <option value="MANAGER">MANAGER</option>
                                  <option value="ADMIN">ADMIN</option>
                                </select>
                              </div>
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> User Approval</label>
                                <select class="form-control" name="userApproval" value={this.state.userApproval} onChange={this.userApprovalHandleChange}>
                                  <option value="">Select Type</option>
                                  <option value="NONE">NONE</option>
                                  <option value="MANAGER">MANAGER</option>
                                  <option value="ADMIN">ADMIN</option>
                                </select>
                              </div>

                            </div>

                            <div class="row">
                              <div class="input-group  col-sm-6 input-group-static mt-4">
                                <label>Start Date</label>
                                <input type="date" class="form-control" name="startDate" placeholder="Start Date" value={this.state.startDate} onChange={this.handlestartDateChange} />

                              </div>
                              <div class="input-group  col-sm-6 input-group-static mt-4">
                                <label>End Date</label>
                                <input type="date" class="form-control" name="endDate" placeholder="End Date" value={this.state.endDate} onChange={this.handleendDateChange} />
                              </div>

                            </div>
                            <div class="row">

                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> Contact Us(HTML)</label>
                                <textarea class="form-control" name="contactHtml" onChange={this.handleInputChange}>{this.state.contactHtml}</textarea>
                              </div>
                            </div>
                            <div class="row">
                              <div class="card-header p-3 pt-2 mt-2">

                                <h6 class="mb-0">Program Config</h6>

                              </div>
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> Tier Type</label>
                                <select class="form-control" name="tierType" value={this.state.tierType} onChange={this.handleInputChange}>
                                  <option value="">Select Type</option>
                                  <option value="NONE">NONE</option>
                                  <option value="BOTH">BOTH</option>
                                  <option value="ACCURAL">ACCURAL</option>
                                  <option value="REDEEM">REDEEM</option>

                                </select>
                              </div>
                              <div class="input-group col-sm-6  input-group-static">
                                <label> Point Value</label>
                                <input type="text" class="form-control" name="pointValue" value={this.state.pointValue} onChange={this.handleInputChange} />

                              </div>
                            </div>
                            <div class="row">
                              <div class="input-group col-sm-6  input-group-static">
                                <label> Welcome Bonus</label>
                                <input type="text" class="form-control" name="bonusWelcome" value={this.state.bonusWelcome} onChange={this.handleInputChange} />

                              </div>
                              <div class="input-group col-sm-6  input-group-static">
                                <label> First Transaction Bonus</label>
                                <input type="text" class="form-control" name="firstTransBonus" value={this.state.firstTransBonus} onChange={this.handleInputChange} />

                              </div>
                              <div class="input-group col-sm-6  input-group-static">
                                <label> Anniversary Bonus</label>
                                <input type="text" class="form-control" name="bonusAnniversary" value={this.state.bonusAnniversary} onChange={this.handleInputChange} />

                              </div>
                              <div class="input-group col-sm-6  input-group-static">
                                <label> Birthday Bonus</label>
                                <input type="text" class="form-control" name="bonusBirthday" value={this.state.bonusBirthday} onChange={this.handleInputChange} />

                              </div>
                            </div>

                            <div class="row">
                              <div class="input-group col-sm-6  input-group-static">
                                <label> Apply Limit</label>
                                <input type="checkbox" value={true} name="apply" onChange={() => this.handleInputChange} checked={this.state.apply} />
                              </div>
                              <div class="input-group col-sm-6  input-group-static">
                                <label> User Block after Anomaly</label>
                                <input type="checkbox" value={true} name="userBlock" onChange={() => this.handleInputChange} checked={this.state.userBlock} />
                              </div>

                            </div>
                            <div class="row">
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> Limit Scans per Day</label>
                                <input type="number" class="form-control" name="scanPerday" placeholder="" value={this.state.scanPerday} onChange={this.handleInputChange} />

                              </div>
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> Limit Scans per Month</label>
                                <input type="number" class="form-control" name="scanPerMonth" placeholder="" value={this.state.scanPerMonth} onChange={this.handleInputChange} />

                              </div>
                            </div>
                            <div class="row">
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> Limit Redemptions per Day</label>
                                <input type="number" class="form-control" name="redeemPerDay" placeholder="" value={this.state.redeemPerDay} onChange={this.handleInputChange} />

                              </div>
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> Limit Redemptions per Month</label>
                                <input type="number" class="form-control" name="redeemPerMonth" placeholder="" value={this.state.redeemPerMonth} onChange={this.handleInputChange} />

                              </div>
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label> Limit Redemptions per Week</label>
                                <input type="number" class="form-control" name="redeemPerWeek" placeholder="" value={this.state.redeemPerWeek} onChange={this.handleInputChange} />

                              </div>
                            </div>
                            <div class="row">
                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label>SMS Vendor</label>
                                <input type="text" class="form-control" name="smsVendor" placeholder="" value={this.state.smsVendor} onChange={this.handleInputChange} readOnly />

                              </div>


                              <div class="input-group col-sm-6  input-group-static mt-2">
                                <label>SMS URL</label>
                                <input type="text" class="form-control" name="smsURl" placeholder="" value={this.state.smsURl} onChange={this.handleInputChange} readOnly />

                              </div>

                            </div>
                            <div class="row">
                              <div class="card-header p-3 pt-2 mt-2">

                                <h6 class="mb-0">Program Details</h6>

                              </div>
                              <div class="row my-2">
                                <div class="input-group  col-sm-12 input-group-static mt-1">
                                  <label>Title</label>
                                  <input type="text" class="form-control" name="title" placeholder=" Title" value={this.state.title} onChange={this.handletitleChange} />
                                </div>

                              </div>
                              <div class="row my-2">
                                <div class="input-group  col-sm-12 input-group-static my-1">
                                  <label>Tagline</label>
                                  <input type="text" class="form-control" name="tagline" placeholder=" Tagline" value={this.state.tagline} onChange={this.handletaglineChange} />
                                </div>

                              </div>
                              <div class="row my-2">
                                <div class="input-group  col-sm-12 input-group-static my-1">
                                  <label>Short Description</label>
                                  <input type="text" class="form-control" name="descriptionShort" placeholder=" Short Description" value={this.state.descriptionShort} onChange={this.handledescriptionShortChange} />
                                </div>

                              </div>
                              <div class="row my-2">
                                <div class="input-group  col-sm-12 input-group-static my-1">
                                  <label>Long Description</label>
                                  <input type="text" class="form-control" name="descriptionLong" placeholder=" Long Description" value={this.state.descriptionLong} onChange={this.handledescriptionLongChange} />
                                </div>

                              </div>
                              <div class="row my-2">


                                <div class="input-group  col-sm-12 input-group-static my-1">
                                  <label>Tnc</label>

                                  <textarea class="form-control" name="tnc" placeholder="Tnc" value={this.state.tnc} onChange={this.handletncChange} ></textarea>
                                </div>
                                <div class="input-group  col-sm-12 input-group-static my-1">
                                  <label>Privacy Policy</label>

                                  <textarea class="form-control" name="privacy" placeholder="Privacy Policy" value={this.state.privacy} onChange={this.handleprivacyChange}  ></textarea>

                                </div>
                                <div class="input-group  col-sm-12 input-group-static my-1">
                                  <label>FAQ</label>

                                  <textarea class="form-control" name="faq" placeholder="FAQ" value={this.state.faqs} onChange={this.handlefaqsChange}  ></textarea>

                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-auto col-6">
                                {this.state.catalog != '' ?
                                  <a href={this.state.catelog}>View Catalog</a> : ''}

                                <div class="input-group  col-sm-6 input-group-static mt-4">
                                  <label for="file-input">
                                    Catalog
                                  </label>
                                  <input type="file" name="catelog" onChange={this.onCatalogChange}
                                  />
                                </div>
                              </div>
                              <div class="col-sm-auto col-6">
                                {this.state.manual != '' ? <a href={this.state.manual}>View Manual</a> : ''}

                                <div class="input-group  col-sm-6 input-group-static mt-4">
                                  <label for="file-input">
                                    Manual
                                  </label>
                                  <input type="file" name="manual" onChange={this.onManualChange}
                                  />
                                </div>
                              </div>
                              <div class="col-sm-auto col-6">
                                {this.state.whatsNew != '' ? <a href={this.state.whatsNew}>View Manual</a> : ''}

                                <div class="input-group  col-sm-6 input-group-static mt-4">
                                  <label for="file-input">
                                    Whats New
                                  </label>
                                  <input type="file" name="whatsNew" onChange={this.onwhatsNewChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="d-flex justify-content-end mt-4">
                              <button type="button" name="button" class="btn btn-light m-0" onClick={() => this.setState({ create: 0 })}>Cancel</button>&nbsp;
                              <button type="button" name="button" class="btn bg-gradient-primary m-0 ms-2" onClick={this.saveProgramDetail}>Save</button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </> : ''}


                  {this.state.currentPage == 'bonuses' ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <div class="card-header p-3 pt-2">
                          <div
                            class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                            <i class="material-icons opacity-10">star</i>
                          </div>
                          <h6 class="mb-0">Bonuses</h6>
                        </div>
                        <div class="card-body pt-2">
                          {this.state.regions.map(function (item, i) {
                            return (<>
                              <div class="card mt-4">
                                <div class="row p-2">
                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <label>Region Name</label>

                                  </div>
                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <input type="text" class="form-control" name="name" placeholder="Region" onChange={(e) => handleRegionChange(i, e)} />

                                  </div>
                                </div>
                                <div class="row p-2">

                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <label>States</label>

                                  </div>
                                  <div class="input-group  col-sm-6 input-group-static my-4" >
                                    {states.map(function (item, i) {
                                      return (
                                        <>
                                          <div class="input-group  col-sm-6 input-group-static my-4">
                                            <input type="checkbox" /> &nbsp;{item.name}
                                          </div>
                                        </>
                                      )
                                    })}

                                  </div>
                                </div>

                                <div class="row p-2">
                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <label>Welcome Points</label>

                                  </div>
                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <input type="text" class="form-control" name="welcome" placeholder="points" onChange={(e) => handleRegionChange(i, e)} />

                                  </div>
                                </div>
                                <div class="row p-2">
                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <label>Anniversary Points</label>

                                  </div>
                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <input type="text" class="form-control" name="name" placeholder="points" />

                                  </div>
                                </div>
                                <div class="row p-2">
                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <label>Birthday Points</label>

                                  </div>
                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <input type="text" class="form-control" name="name" placeholder="points" />

                                  </div>
                                </div>
                                <div class="row p-2">
                                  <div class="input-group  col-sm-6 input-group-static my-4">
                                    <label>Milestone Points</label>

                                  </div>
                                  <div class="input-group  col-sm-3 input-group-static my-4">
                                    <input type="text" class="form-control" name="name" placeholder="Milestone Name" />

                                  </div>
                                  <div class="input-group  col-sm-3 input-group-static my-4">
                                    <input type="text" class="form-control" name="name" placeholder="points" />

                                  </div>

                                </div>
                              </div></>)
                          })}
                          <div class="row  mt-4">

                            <button class="btn bg-gradient-dark btn-sm col-sm-3 float-end mb-0" onClick={addRegions}
                            >Add More Regions</button>
                          </div>
                          <div class="d-flex justify-content-end mt-4">
                            <button type="button" name="button" class="btn btn-light m-0" onClick={() => this.setState({ create: 0 })}>Cancel</button>&nbsp;
                            <button type="button" name="button" class="btn bg-gradient-primary m-0 ms-2" onClick={this.saveRegion}>Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ''}


                  {this.state.currentPage == 'tiers' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <ProgramTiers />
                        </div>
                      </div>
                    </>
                    : ''}
                  {this.state.currentPage == 'users' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <ProgramMembers />
                        </div>
                      </div>
                    </>
                    : ''}
                  {this.state.currentPage == 'invoice' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <ProgramInvoices />
                        </div>
                      </div>
                    </>
                    : ''}
                  {this.state.currentPage == 'usersbysales' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <ProgramMemberbySales />
                        </div>
                      </div>
                    </>
                    : ''}
                  {this.state.currentPage == 'issuetypes' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <IssueTypes />
                        </div>
                      </div>
                    </>
                    : ''}
                  {this.state.currentPage == 'products' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <Products />
                        </div>
                      </div>
                    </>

                    : ''}
                  {this.state.currentPage == 'areas' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <ProgramArea />
                        </div>
                      </div>
                    </>

                    : ''}
                  {this.state.currentPage == 'tickets' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <ProgramTickets />
                        </div>
                      </div>
                    </>

                    : ''}
                  {this.state.currentPage == 'redemptionproducts' ?
                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <ProgramRedeem />
                        </div>
                      </div>
                    </>

                    : ''}
                  {this.state.currentPage == 'managers' ?

                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <ProgramManagers />
                        </div>
                      </div>
                    </>
                    : ''}
                  {this.state.currentPage == 'banners' ?

                    <>
                      <div class="col-lg-9 col-12 mx-auto position-relative">
                        <div class="card">
                          <ProgramBanners />
                        </div>
                      </div>
                    </>
                    : ''}
                  {this.state.currentPage == 'communications' ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <ProgramComms />
                      </div>
                    </div>
                    : ''}
                  {this.state.currentPage == 'regions' ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <ProgramRegions />
                      </div>
                    </div>
                    : ''}
                  {this.state.currentPage == 'branches' ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <ProgramBranch />
                      </div>
                    </div>
                    : ''}
                  {this.state.currentPage == 'orders' ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <Orders />
                      </div>
                    </div>
                    : ''}
                  {this.state.currentPage == 'loginreport' ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <LoginReport />
                      </div>
                    </div>
                    : ''}
                  {this.state.currentPage == 'enrollmentreport' ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <EnrollmentReport />
                      </div>
                    </div>
                    : ''}
                  {this.state.currentPage == 'qrreport' ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <SalesQrTransactionReport />
                      </div>
                    </div>
                    : ''}
                  {this.state.currentPage == "redemptionreport" ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <RedemptionReport />
                      </div>
                    </div>
                    : ''}
                  {this.state.currentPage == "testimonials" ?

                    <div class="col-lg-9 col-12 mx-auto position-relative">
                      <div class="card">
                        <Testimonials />
                      </div>
                    </div>
                    : ''}
                        {this.state.currentPage == "tds" ?

<div class="col-lg-9 col-12 mx-auto position-relative">
  <div class="card">
    <MemberTds />
  </div>
</div>
: ''}
                </div>
              </div>
            </main >



          </>
        ) : this.state.resp ? (
          <CreateMasterProgram resp={this.state.resp} />
        ) : (
          <CreateMasterProgram />
        )
        }
      </>
    );
  }
}

export default CreateChildProgram;
