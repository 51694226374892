import axios from "axios";
import * as Constants from "../../Constants/index";

export const countryData = async () => {
  const token = localStorage.getItem("token");

  await axios
    .get(`${Constants.api}master/geography/country`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(
      (res) => {
        const dt = res.data.output.results;
        if (res.status == 201 && res.data.result != "error") {
          console.log("countryDataStatus==>>>", res.status);
          console.log("countryData==>>>", dt);
          // console.log(this.state.Countries);
          console.log("countryData", res.data);
        } else if (res.status == 403) {
          localStorage.clear();
          window.location.href = "/login";
        }
      },
      (err) => {
        console.log(err);
      }
    );
};
