import React, { Component } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
// import SimpleReactValidator from "simple-react-validator";
// import Switch from "react-switch";
// import ReactDragListView from "react-drag-listview";

class CreateProgramMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobile: "",
      mVerify: false,
      identityType: "",
      identityNumber: "",
      identityCardBackUrl: "",
      identityCardFrontUrl: "",
      aadharMobile: "",
      aadharNumber: "",
      aadharFrontUrl: "",
      aadharBackUrl: "",
      aadharVerify: 0,
      aadharVerifiedBy: "",
      aadharVerifiedOn: "",
      panNumber: "",
      panFrontUrl: "",
      panVerify: 0,
      panVerifiedBy: "",
      panVerifiedOn: "",
      workAddress1: "",
      workAddress2: "",
      workAddress3: "",
      workCity: "",
      workCityId: null,
      workState: "",
      workStateId: null,
      workdistrict: "",
      workdistrictId: null,
      identityCardBackUrl: "",
      identityCardFrontUrl: "",
      identityNumber: "",
      identityType: "",
      workPincode: "",
      workCountry: "",
      bankAccountNo: "",
      accountType: "",
      ifsc: "",
      beneficiary: "",
      bankName: "",
      holderName: "",
      branchName: "",
      cancelChk: "",
      upiId: "",
      upiIdScreen: "",
      points: 0,
      totalPoints: 0,
      redeemPoints: 0,
      userType: "",
      parentType: "",
      parentId: null,
      roId: null,
      shId: null,
      parents: [],
      status: "",
      firstScan: null,
      lastScan: null,
      lastPayOutDate: null,
      approval: null,
      platform: "",
      appVersion: "",
      registerFrom: "",
      profilePic: "",
      profileVerify: false,
      createDateTime: "",
      sStateId: null,
      sDistrictId: null,
      sCityId: null,
      editMode: false,
      aadharFront: '',
      aadharBack: '',
      cancelChkPhoto: '',
      identityCardFront: '',
      identityCardBack: ''
    };

  }

  componentDidMount() {
    this.setFieldsFromProps();
    // this.SALESRequest();
  }
  SALESRequest = (parentType) => {

    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let programid = parts.pop();
    programid = parseInt(programid);
    const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
    let compid = pathSegments[pathSegments.length - 2];
    try {
      const token = localStorage.getItem("token");
      let url = "";
      this.state.search != ""
        ? (url =
          "user/manage?" +
          "name=" +
          "" +
          "&mobile=" +
          "" +
          "&userType=" + parentType +
          "&sortBy=" +
          "name" +
          "&limit=" +
          "10000" +
          "&page=" +
          "1")
        : (url =
          "user/manage?sortBy=" +
          "name" +
          "&userType=" + parentType +
          "&limit=" +
          "10000" +
          "&page=" +
          "1");
      const resp = axios
        .get(Constants.api + url, {
          headers: {
            Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
            platform: Constants.pt
          },

        })
        .then((resp) => {
          if (resp.status == 201 && resp.data.result != "error") {
            console.log(resp.data.output.results);
            this.setState({
              parents: resp.data.output.results,
            });
            console.log(this.state.members);
          } else {
          }
        }).catch((err) => {
          // window.location = "/login"
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.resp !== prevProps.resp) {
      this.setFieldsFromProps();
    }
  }

  setFieldsFromProps() {
    const { resp } = this.props;
    if (resp && resp.data.output) {
      const data = resp.data.output;
      this.setState({
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        mVerify: data.mVerify,
        identityType: data.identityType,
        identityNumber: data.identityNumber,
        identityCardFrontUrl: data.identityCardFrontUrl,
        identityCardBackUrl: data.identityCardBackUrl,
        aadharMobile: data.userModelKYC ? data.userModelKYC.aadharMobile : '',
        aadharNumber: data.userModelKYC ? data.userModelKYC.aadharNumber.replace(/\s/g, '') : '',
        aadharFrontUrl: data.userModelKYC ? data.userModelKYC.aadharFrontUrl : '',
        aadharBackUrl: data.userModelKYC ? data.userModelKYC.aadharBackUrl : '',
        aadharVerify: data.userModelKYC ? data.userModelKYC.aadharVerify : '',
        aadharVerifiedBy: data.userModelKYC ? data.userModelKYC.aadharVerifiedBy : '',
        aadharVerifiedOn: data.userModelKYC ? data.userModelKYC.aadharVerifiedOn : '',
        panNumber: data.userModelKYC ? data.userModelKYC.panNumber : '',
        panFrontUrl: data.userModelKYC ? data.userModelKYC.panFrontUrl : '',
        panVerify: data.userModelKYC ? data.userModelKYC.panVerify : '',
        panVerifiedBy: data.userModelKYC ? data.userModelKYC.panVerifiedBy : '',
        panVerifiedOn: data.userModelKYC ? data.userModelKYC.panVerifiedOn : '',
        workAddress1: data.addressWork ? data.addressWork.workAddress1 : '',
        workAddress2: data.addressWork ? data.addressWork.workAddress2 : '',
        workAddress3: data.addressWork ? data.addressWork.workAddress3 : '',
        workCity: data.addressWork ? data.addressWork.workCity : '',
        workCityId: data.addressWork ? data.addressWork.workCityId : '',
        workState: data.addressWork ? data.addressWork.workState : '',
        workStateId: data.addressWork ? data.addressWork.workStateId : '',
        workdistrict: data.addressWork ? data.addressWork.workdistrict : '',
        workdistrictId: data.addressWork ? data.addressWork.workdistrictId : '',
        workPincode: data.addressWork ? data.addressWork.workPincode : '',
        workCountry: data.addressWork ? data.addressWork.workCountry : '',
        bankAccountNo: data.bankDetail ? data.bankDetail.bankAccountNo : '',
        accountType: data.bankDetail ? data.bankDetail.accountType : '',
        ifsc: data.bankDetail ? data.bankDetail.ifsc : '',
        beneficiary: data.bankDetail ? data.bankDetail.beneficiary : '',
        bankName: data.bankDetail ? data.bankDetail.bankName : '',
        holderName: data.bankDetail ? data.bankDetail.holderName : '',
        branchName: data.bankDetail ? data.bankDetail.branchName : '',
        cancelChk: data.bankDetail ? data.bankDetail.cancelChk : '',
        upiId: data.bankDetail ? data.bankDetail.upiId : '',
        upiIdScreen: data.bankDetail ? data.bankDetail.upiIdScreen : '',
        points: data.points,
        totalPoints: data.totalPoints,
        redeemPoints: data.redeemPoints,
        userType: data.userType,
        parentType: data.parentType,
        parentId: data.parentId,
        roId: data.roId,
        shId: data.shId,
        status: data.status,
        firstScan: data.firstScan,
        lastScan: data.lastScan,
        lastPayOutDate: data.lastPayOutDate,
        approval: data.approval,
        platform: data.platform,
        appVersion: data.appVersion,
        registerFrom: data.registerFrom,
        profilePic: data.profilePic,
        profileVerify: data.profileVerify,
        createDateTime: data.createDateTime,
        sStateId: data.sStateId,
        sDistrictId: data.sDistrictId,
        sCityId: data.sCityId,

      });
      this.SALESRequest("SALES");
    }
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };


  handleFormSubmit = (event) => {
    event.preventDefault();

    const token = localStorage.getItem("token");
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let programid = parts.pop();
    programid = parseInt(programid);
    const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
    let compid = pathSegments[pathSegments.length - 2];
    if (this.props.resp) {
      // Get the last second segment

      try {

        axios
          .patch(
            Constants.api + "user/manage/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,
              "email": this.state.email,
              "mobile": this.state.mobile,
              "password": this.state.password,
              "status": this.state.status,
              "userModelKYC": {
                "aadharMobile": this.state.aadharMobile,
                "aadharNumber": this.state.aadharNumber.replace(/\s/g, ''),
                "aadharFrontUrl": this.state.aadharFrontUrl,
                "aadharBackUrl": this.state.aadharBackUrl,

                // "panNumber": this.state.panNumber,
                // "panFrontUrl": this.state.panFrontUrl,
                // "panVerify": this.state.panVerify,
                // "panVerifiedBy": this.state.panVerifiedBy,
                // "panVerifiedOn": this.state.panVerifiedOn
              },
              "addressWork": {
                "workAddress1": this.state.workAddress1,
                "workAddress2": this.state.workAddress2,
                "workAddress3": this.state.workAddress3,
                "workCity": this.state.workCity,
                "workCityId": this.state.workCityId,
                "workState": this.state.workState,
                "workStateId": this.state.workStateId,
                "workdistrict": this.state.workdistrict,
                "workdistrictId": this.state.workdistrictId,
                "workPincode": this.state.workPincode,
                "workCountry": this.state.workCountry
              },
              "bankDetail": {
                "bankAccountNo": this.state.bankAccountNo,
                "accountType": this.state.accountType,
                "ifsc": this.state.ifsc,
                "beneficiary": this.state.beneficiary,
                "bankName": this.state.bankName,
                "holderName": this.state.holderName,
                "branchName": this.state.branchName,
                "cancelChk": this.state.cancelChk,
                "upiId": this.state.upiId,
                "upiIdScreen": this.state.upiIdScreen
              },
              "parentId": this.state.parentId,
              "userType": this.state.userType,
              "identityType": this.state.identityType,
              "identityNumber": this.state.identityNumber,
              "identityCardBackUrl": this.state.identityCardBackUrl,
              "identityCardFrontUrl": this.state.identityCardFrontUrl,

              "getsStateId": this.state.getsStateId,
              "getsDistrictId": this.state.getsDistrictId,
              "getsCityId": this.state.getsCityId
            }



            ,

            {
              headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              swal("Member Updated");
              this.onCancelTrigger(0);
            } else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    }
    else {
      // Get the last second segment

      try {
        axios
          .post(
            Constants.api + "user/manage",
            {
              "name": this.state.name,
              "email": this.state.email,
              "mobile": this.state.mobile,
              "password": this.state.password,
              "status": this.state.status,
              "userModelKYC": {
                "aadharMobile": this.state.aadharMobile,
                "aadharNumber": this.state.aadharNumber.replace(/\s/g, ''),
                "aadharFrontUrl": this.state.aadharFrontUrl,
                "aadharBackUrl": this.state.aadharBackUrl,

                // "panNumber": this.state.panNumber,
                // "panFrontUrl": this.state.panFrontUrl,
                // "panVerify": this.state.panVerify,
                // "panVerifiedBy": this.state.panVerifiedBy,
                // "panVerifiedOn": this.state.panVerifiedOn
              },
              "addressWork": {
                "workAddress1": this.state.workAddress1,
                "workAddress2": this.state.workAddress2,
                "workAddress3": this.state.workAddress3,
                "workCity": this.state.workCity,
                "workCityId": this.state.workCityId,
                "workState": this.state.workState,
                "workStateId": this.state.workStateId,
                "workdistrict": this.state.workdistrict,
                "workdistrictId": this.state.workdistrictId,
                "workPincode": this.state.workPincode,
                "workCountry": this.state.workCountry
              },
              "bankDetail": {
                "bankAccountNo": this.state.bankAccountNo,
                "accountType": this.state.accountType,
                "ifsc": this.state.ifsc,
                "beneficiary": this.state.beneficiary,
                "bankName": this.state.bankName,
                "holderName": this.state.holderName,
                "branchName": this.state.branchName,
                "cancelChk": this.state.cancelChk,
                "upiId": this.state.upiId,
                "upiIdScreen": this.state.upiIdScreen
              },
              "parentId": this.state.parentId,
              "userType": this.state.userType,
              "identityType": this.state.identityType,
              "identityNumber": this.state.identityNumber,
              "identityCardBackUrl": this.state.identityCardBackUrl,
              "identityCardFrontUrl": this.state.identityCardFrontUrl,

              "getsStateId": this.state.getsStateId,
              "getsDistrictId": this.state.getsDistrictId,
              "getsCityId": this.state.getsCityId
            }

            ,

            {
              headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              swal("Member Added");
              // this.setState({ create: 0 })
              this.onCancelTrigger(0);
            } else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    }

  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  onCancelTrigger = (event) => {
    this.props.setViewMode(event);
    event.preventDefault();
  }
  onAdharfrontChange = (event) => {
    const selectedFile = event.target.files[0];

    // Check if a file was selected
    if (!selectedFile) {
      return;
    }

    // Update the state with the selected file
    this.setState({ aadharFront: selectedFile }, () => {
      this.updateImage('aadharFront');
    });
  };
  onAdharBackChange = (event) => {
    const selectedFile = event.target.files[0];

    // Check if a file was selected
    if (!selectedFile) {
      return;
    }

    // Update the state with the selected file
    this.setState({ aadharBack: selectedFile }, () => {
      this.updateImage('aadharBack');
    });
  };
  onCancelChkChange = (event) => {
    const selectedFile = event.target.files[0];

    // Check if a file was selected
    if (!selectedFile) {
      return;
    }

    // Update the state with the selected file
    this.setState({ cancelChkPhoto: selectedFile }, () => {
      this.updateImage('cancelChkPhoto');
    });
  };
  onidentityCardBackChange = (event) => {
    const selectedFile = event.target.files[0];

    // Check if a file was selected
    if (!selectedFile) {
      return;
    }

    // Update the state with the selected file
    this.setState({ identityCardBack: selectedFile }, () => {
      this.updateImage('identityCardBack');
    });
  };
  onidentityCardFrontChange = (event) => {
    const selectedFile = event.target.files[0];

    // Check if a file was selected
    if (!selectedFile) {
      return;
    }

    // Update the state with the selected file
    this.setState({ identityCardFront: selectedFile }, () => {
      this.updateImage('identityCardFront');
    });
  };
  updateImage = (type) => {
    let token = localStorage.getItem("token");

    const formData = new FormData();
    if (type == 'aadharFront')
      formData.append("file", this.state.aadharFront);
    if (type == 'aadharBack')
      formData.append("file", this.state.aadharBack);
    if (type == 'identityCardFront')
      formData.append("file", this.state.identityCardFront);
    if (type == 'identityCardBack')
      formData.append("file", this.state.identityCardBack);
    if (type == 'cancelChkPhoto')
      formData.append("file", this.state.cancelChkPhoto);
    formData.append("type", type);


    axios
      .post(
        Constants.api + "uploader/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: 0, program: 0
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {
          swal(type + " Added");
          if (type == 'aadharFront')
            this.setState({ aadharFrontUrl: res.data.output.url });
          if (type == 'aadharBack')
            this.setState({ aadharBackUrl: res.data.output.url });
          if (type == 'cancelChkPhoto')
            this.setState({ cancelChk: res.data.output.url });
          if (type == 'identityCardFront')
            this.setState({ identityCardFrontUrl: res.data.output.url });

          if (type == 'identityCardBack')
            this.setState({ identityCardBackUrl: res.data.output.url });

        } else if (res.status == 403) {
          localStorage.removeItem("userid");
          window.location.href = "/login";
        } else {
          // this.setState({alert:res.data.msg});
          swal("Something went wrong!")
        }
      })
  };
  render() {

    if (this.state.create === 0) {
      window.location.reload()
    }
    const editMode = true;
    let parentId = this.state.parentId;

    return (
      <>
        <main>
          <div className="container">
            <h2>View Member</h2>
            <form onSubmit={this.handleFormSubmit}>
              <div class="row">
                <div className="form-group col-sm-3">
                  <label>Name</label>
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label>Mobile</label>
                  <input
                    className="form-control"
                    name="mobile"
                    type="text"
                    value={this.state.mobile}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div class="col-sm-6">
                  <div class=" mx-4 input-group-outline my-2">
                    <b> Parent:</b>  {this.state.parents.map(function (item, i) {
                      let selected = '';
                      if (parentId == String(item.id))
                        return (
                          <>
                            {item.name}
                          </>
                        )
                    }
                    )}
                  </div>
                  <div class=" mx-4 input-group-outline my-2">





                  </div>
                </div>
                <div className="form-group col-sm-3">
                  <label>ID Type</label>
                  <input
                    className="form-control"
                    name="identityType"
                    type="text"
                    value={this.state.identityType}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label>ID Number</label>
                  <input
                    className="form-control"
                    name="identityNumber"
                    type="text"
                    value={this.state.identityNumber}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label>ID Front Image</label>
                  <img src={this.state.identityCardFrontUrl} width="150" />
                  <input type="file" onChange={this.onidentityCardFrontChange} />

                </div>
                <div className="form-group col-sm-3">
                  <label>ID Back Image</label>
                  <img src={this.state.identityCardBackUrl} width="150" />
                  <input type="file" onChange={this.onidentityCardBackChange} />

                </div>
                <div className="form-group col-sm-3">
                  <label>Aadhar Number</label>
                  <input
                    className="form-control"
                    name="aadharNumber"
                    type="text"
                    value={this.state.aadharNumber.replace(/\s/g, '')}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label> Mobile Linked with Aadhaar</label>
                  <input
                    className="form-control"
                    name="aadharMobile"
                    type="text"
                    value={this.state.aadharMobile}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
              </div>
              <div class="row">
                <div className="form-group col-sm-3">
                  <label>Aadhar Front Image</label>

                  <img src={this.state.aadharFrontUrl} width="150" />
                  <input type="file" onChange={this.onAdharfrontChange} />

                </div>
                <div className="form-group col-sm-3">
                  <label>Aadhar Back Image</label>

                  <img src={this.state.aadharBackUrl} width="150" />
                  <input type="file" onChange={this.onAdharBackChange} />

                </div>
                <div className="form-group col-sm-3">
                  <label>Email</label>
                  <input
                    className="form-control"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>

                <div className="form-group col-sm-3">
                  <label> Address 1</label>
                  <input
                    className="form-control"
                    name="workAddress1"
                    type="text"
                    value={this.state.workAddress1}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
              </div>
              <div class="row">
                <div className="form-group col-sm-4">
                  <label> Address 2</label>
                  <input
                    className="form-control"
                    name="workAddress2"
                    type="text"
                    value={this.state.workAddress2}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-4">
                  <label> Address 3</label>
                  <input
                    className="form-control"
                    name="workAddress3"
                    type="text"
                    value={this.state.workAddress3}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-4">
                  <label> State</label>
                  <input
                    className="form-control"
                    name="workState"
                    type="text"
                    value={this.state.workState}
                    onChange={this.handleInputChange}
                    disabled={true}
                  />
                </div>
                <div className="form-group col-sm-4">
                  <label> City</label>
                  <input
                    className="form-control"
                    name="workCity"
                    type="text"
                    value={this.state.workCity}
                    onChange={this.handleInputChange}
                    disabled={true}
                  />
                </div>
                <div className="form-group col-sm-4">
                  <label> Pincode</label>
                  <input
                    className="form-control"
                    name="workPincode"
                    type="text"
                    value={this.state.workPincode}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-sm-3">
                  <label>Account Holder Name</label>
                  <input
                    className="form-control"
                    name="holderName"
                    type="text"
                    value={this.state.holderName}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label>Account Type</label>
                  <input
                    className="form-control"
                    name="accountType"
                    type="text"
                    value={this.state.accountType}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label>Bank Name</label>
                  <input
                    className="form-control"
                    name="bankName"
                    type="text"
                    value={this.state.bankName}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label>Bank Account No</label>
                  <input
                    className="form-control"
                    name="bankAccountNo"
                    type="text"
                    value={this.state.bankAccountNo}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label>Cheque</label>
                  <br />
                  <img src={this.state.cancelChk} width="150" />
                  <input type="file" onChange={this.onCancelChkChange} />

                </div>
                <div className="form-group col-sm-3">
                  <label>UPI ID</label>
                  <input
                    className="form-control"
                    name="upiId"
                    type="text"
                    value={this.state.upiId}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label>IFSC</label>
                  <input
                    className="form-control"
                    name="ifsc"
                    type="text"
                    value={this.state.ifsc}
                    onChange={this.handleInputChange}
                    disabled={!editMode}
                  />
                </div>
                <div class=" mt-4" id="delete">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                      <div class="w-50">
                        {/* <p>Save Country</p> */}
                        {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                      </div>
                      <div class="w-50 text-end">
                        <button
                          class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto"
                          type="button"
                          name="button"
                          onClick={() => {
                            // this.setState({ create: 0 })
                            this.onCancelTrigger(0);
                          }}
                        >Cancel</button>
                        <button class="btn bg-gradient-success mb-0 ms-2" onClick={() =>
                          this.handleFormSubmit
                        } type="submit" name="button" >Save </button>
                      </div>
                    </div>
                  </div>
                </div>


              </div>







            </form></div> </main >
      </>
    );
  }

}

export default CreateProgramMembers;
