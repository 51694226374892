import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { matchPath } from 'react-router';
import ReactDragListView from "react-drag-listview";
import CpCategorys from "components/masters/cproduct/categorys/cpCategorys.component";
import Products from "components/masters/cproduct/products/products.component";
import CompanyLocations from "components/companyLocations/companyLocations.component";
import CompanyContacts from "components/companyContacts/companyContacts.component";
import CompanyPartners from "components/companyPartners/companyPartners.component";

class ManageCompany extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
  }

  state = {
    name: "",
    sortBy: "name",
    limit: 100000,
    totalResults: 0,
    page: 1,
    program: {},
    search: "",
    create: 0,
    createTemplate: 0,
    id: this.props.match.params.id,
    currentPage: this.props.match.params.section ? this.props.match.params.section : "locations",
    users: [],
    locations: [],
    locatioGst: "",
    locationName: ""
    // company: {}
  }


  componentDidMount() {
    try {
      const token = localStorage.getItem("token");
      const deletedata = new FormData();
      deletedata.append("id", this.state.id); //append the values with key, value pair

      axios
        .get(
          Constants.api + "company/" + this.state.id,
          {
            headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
          },
          deletedata
        )
        .then((resp) => {
          //  casetype array

          if (resp.status == 200) {
            this.setState({ company: resp.data.output });
          }  else if (resp.status == 403) {
            localStorage.removeItem("userid");
            window.location.href = "/login";
          } else {
          }
          //movie array
        }).catch((err) => {
          //  window.location = "/login"
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }


  }

  render() {


    let programId = this.state.id;
    return (
      <>

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav
            class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky"
            id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="/company" >Company</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">{this.state.company ? this.state.company.name : ''}</li>
                </ol>
              </nav>

              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>

            </div>
          </nav>

          <div class="container-fluid py-4">
            <div class="row mt-4">
              <div class="col-lg-3">
                <div class="card position-sticky top-1">
                  <ul class="nav flex-column bg-white border-radius-lg p-3">

                    <li class="nav-item">
                      <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'locations' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "locations" })}>
                        <i class="material-icons text-lg me-2">place</i>
                        <span class="text-sm">Locations</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'contacts' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "contacts" })}>
                        <i class="material-icons text-lg me-2">contact_phone</i>
                        <span class="text-sm">Contacts</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class={`nav-link text-dark d-flex ${this.state.currentPage == 'partners' ? "btn-warning" : ''}`} onClick={() => this.setState({ currentPage: "partners" })}>
                        <i class="material-icons text-lg me-2">group</i>
                        <span class="text-sm">Partners</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {this.state.currentPage == 'contacts' ?
                <div class="col-lg-9 col-12 mx-auto position-relative">
                  <div class="card">
                    <CompanyContacts />
                  </div>
                </div>
                : ''}
              {this.state.currentPage == 'locations' ?
                <div class="col-lg-9 col-12 mx-auto position-relative">
                  <div class="card">
                    <CompanyLocations />
                  </div>
                </div>
                : ''}
              {this.state.currentPage == 'partners' ?
                <div class="col-lg-9 col-12 mx-auto position-relative">
                  <div class="card">
                    <CompanyPartners />
                  </div>
                </div>
                : ''}
            </div>
          </div>
        </main >



      </>
    );
  }
}

export default ManageCompany;
