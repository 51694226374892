import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import CreateProgramInvoices from "./createprogramInvoices.component";
import Modal from 'react-modal';

import '../../pages/modal/modal.style.css'; // Import a custom CSS file for styling

class ProgramInvoices extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
  }

  state = {
    name: "",
    sortBy: "createDateTime",
    limit: 25,
    totalResults: 0,
    page: 1,
    members: [],
    search: "",
    create: 0,
    view: 0,
    verifyModal: false,
    status: "",
    remarks: "",
    reason: "",
    memberId: 0,
    showInvoiceImageModal: false,
    imageUrl: '',
    scale: 1,
    status: 'NEW,REJECT,APPROVED,VERIFIED,BLACKLIST'
  };

  componentDidMount() {
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let programid = parts.pop();
    programid = parseInt(programid);
    const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
    let compid = pathSegments[pathSegments.length - 2];
    const memberRequest = async () => {

      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "capture/invoice/captureQrInvoiceHistory/admin?sortAsc=true" +
            "&search=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "capture/invoice/captureQrInvoiceHistory/admin?sortAsc=true&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: {
              Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
              platform: Constants.pt
            },

          })
          .then((resp) => {
            //  members array

            if (resp.data.code == 10001) {
              this.setState({
                members: resp.data.output.results,
                totalResults: resp.data.output.totalResults
              });
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              this.setState({
                members: [],
                totalResults: 0
              });
            }
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    memberRequest();
  }

  handleInputChange = (event) => {

    const target = event.target;
    const name = target.name;

    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name == 'status')

      this.setState(
        (prevState) => ({
          ...prevState,
          [name]: value,
        }),
        () => {
          this.componentDidMount()
        });


    else
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
  };
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }
  handleViewMode = (data) => {
    this.setState({ create: data });
    this.componentDidMount();
  };
  // setViewMode={this.handleViewMode}

  handleZoomIn() {
    this.setState((prevState) => ({
      scale: prevState.scale + 0.1,
    }));
  }

  handleZoomOut() {
    this.setState((prevState) => ({
      scale: prevState.scale - 0.1,
    }));
  }
  

  render() {
    const activateUser = (id) => {
      try {
        const token = localStorage.getItem("token");

        axios
          .patch(
            Constants.api + "user/update/deactive/" + id, "",
            {
              headers: {
                Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language"), appVersion: Constants.av,
                platform: Constants.pt
              },
            },

          )
          .then((resp) => {
            //  casetype array
            swal("Done")
            if (resp.status == 200) {
              this.setState({ verifyModal: false }, this.componentDidMount());

            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    const approveMember = (id) => {
      try {
        const token = localStorage.getItem("token");

        const currentURL = window.location.pathname;
        const parts = currentURL.split("/");
        let programid = parts.pop();
        programid = parseInt(programid);
        const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
        let compid = pathSegments[pathSegments.length - 2];

        axios
          .post(
            Constants.api + "capture/invoice/manage/updatestatus/" + id, {
            status: this.state.status,
            remarks: this.state.remarks,
            reason: this.state.reason
          },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
              
            },
          )
          .then((resp) => {
            //  casetype array
            swal("Status Updated")
            if (resp.status == 200) {
              this.setState({ verifyModal: false }, this.componentDidMount());

            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "user/manage/" + id,
            {
              headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    const viewItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "user/manage/" + id,
            {
              headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ view: 1, create: 1, resp: resp });
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "user/manage/" + id,
              {
                headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
              }


            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 200) {
                this.componentDidMount();
              } else if (resp.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };
    let verifyModal = (id) => {
      this.setState({ verifyModal: !this.state.verifyModal, memberId: id })
    }
  
    let openImage = (imageUrl) => {
      this.setState({
        showInvoiceImageModal: true,
        imageUrl: imageUrl
      });
    }


    
    const membersRender = this.state.members.map(function (item, i) {
      let color = ""
      if (!item.mVerify)
        color = "pink"
      else
        color = ""
      return (
        <>
          <tr key={item.id}> 
            <td>
              {item.name}
            </td>
            <td>
              <p>{item.mobile}</p>
            </td>
            <td>
              <p>{item.retailerFirmName}</p>
            </td>
            <td>
              <p>{item.retailerMobile}</p>
            </td>
            <td>
              <div className="invoice-images">
                {item.invoiceUrl1 && <img src={item.invoiceUrl1} height="75px" alt="Invoice 1" className="invoice-image" onClick={() => this.openImage(item.invoiceUrl1)} />}
                <span style={{ width: '5px' }}></span>
                {item.invoiceUrl2 && <img src={item.invoiceUrl2} height="75px" alt="Invoice 2" className="invoice-image" onClick={() => this.openImage(item.invoiceUrl2)} />}
              </div>
              <div className="invoice-images">
                {item.invoiceUrl3 && <img src={item.invoiceUrl3} height="75px" alt="Invoice 3" className="invoice-image" onClick={() => this.openImage(item.invoiceUrl3)} />}
                <span style={{ width: '5px' }}></span>
                {item.invoiceUrl4 && <img src={item.invoiceUrl4} height="75px" alt="Invoice 4" className="invoice-image" onClick={() => this.openImage(item.invoiceUrl4)} />}
              </div>
            </td>
            <td class="date_two">
              <p>{item.status}</p>
            </td>
            <td>
              <p>{item.pendingInvoiceDay}</p>
            </td>
            <td class="date_two">
              {moment(item.invoiceDate).format("MM/DD/YY")}
            </td>
            <td class="date_two">
              {moment(item.createDateTime).format("MM/DD/YY")}
            </td>
            

            <td>
              {item.status == 'VERIFIED' ?
                <button class="btn btn-outline-primary btn-sm" onClick={() => verifyModal(item.id)}
                  ><i class="material-icons text-lg">check_circle</i> Verify</button>
                : ''}
            </td>
          </tr>
        </>
      );

    });

    const { showInvoiceImageModal, imageUrl } = this.state;

    return (
      <>
        {
          this.state.create == 0 ? (
            <>
              {showInvoiceImageModal && (
                <Modal
                  isOpen={showInvoiceImageModal}
                  onRequestClose={() => this.setState({ showInvoiceImageModal: false, imageUrl: '', scale: 1 })}
                  className="custom-modal"
                  style={{ content: { width: '50%', height: '70%', margin: 'auto' } }}
                >
                  <div className="modal-content">
                    <div className="modal-footer">
                      <button onClick={() => this.setState({ showInvoiceImageModal: false, imageUrl: '', scale: 1 })}>Close</button>
                      <button onClick={this.handleZoomIn}>IN</button>
                      <button onClick={this.handleZoomOut}>OUT</button>
                    </div>
                    <div className="modal-body" style={{justifyContent:'center', display:'flex'}}>
                      <img
                        src={imageUrl}
                        className="modal-image"
                        alt="Zoomed Image"
                        height={'235px'}
                        style={{ transform: `scale(${this.state.scale})`, transition: 'transform 0.2s ease'}}
                      />
                    </div>
                    
                  </div>
                </Modal>
              
              )}
              <main class="">
                <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
                  <div class="container-fluid py-1 px-3">
                    <nav aria-label="breadcrumb">
                      <ul class="navbar-nav  justify-content-end">
                        <li class="nav-item">
                          <div class="from_dropdown">
                            <label for="show">Status</label>
                            <select class="form-control" name="status" id="show" onChange={(e) =>
                              this.handleInputChange(e)
                            } >
                              <option
                                value="NEW,REJECT,APPROVED,VERIFIED,BLACKLIST"

                              >
                                All
                              </option>
                              <option
                                value="NEW"

                              >
                                NEW
                              </option>
                              <option
                                value="REJECT"

                              >
                                REJECT
                              </option>
                              <option
                                value="APPROVED"

                              >
                                APPROVED
                              </option>
                              <option
                                value="VERIFIED"

                              >
                                VERIFIED
                              </option>
                              <option
                                value="BLACKLIST"

                              >
                                BLACKLIST
                              </option>


                            </select>
                          </div>
                        </li>

                      </ul>
                    </nav>
                    <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">

                    </div>
                    <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                      <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                        <div class="input-group input-group-outline">
                          <input type="text" class="form-control" placeholder="Search"
                            onChange={this.searchHandleChange} />
                        </div>
                      </div>

                      <ul class="navbar-nav  justify-content-end">
                        <li class="nav-item">
                          <div class="from_dropdown">
                            <label for="show">Show entries</label>
                            <select name="cars" id="show">
                              <option
                                value="25"
                                onClick={
                                  (() => this.setState({ limit: 25 }),
                                    () => this.componentDidMount())
                                }
                              >
                                25
                              </option>
                              <option
                                value="50"
                                onClick={
                                  (() => this.setState({ limit: 50 }),
                                    () => this.componentDidMount())
                                }
                              >
                                50
                              </option>
                              <option
                                value="75"
                                onClick={
                                  (() => this.setState({ limit: 75 }),
                                    () => this.componentDidMount())
                                }
                              >
                                75
                              </option>
                              <option
                                value="100"
                                onClick={
                                  (() => this.setState({ limit: 100 }),
                                    () => this.componentDidMount())
                                }
                              >
                                100
                              </option>
                            </select>
                          </div>
                        </li>

                      </ul>

                    </div>
                  </div>
                </nav>
                <div class="container-fluid py-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-header pb-0">
                          <div class="d-lg-flex">
                            <div>
                              <h5 class="mb-0">All Program Invoices</h5>

                            </div>
                            <div class="ms-auto my-auto mt-lg-0 mt-4">
                              <div class="ms-auto my-auto">
                                {/* <a href="#" class="btn bg-gradient-primary mb-2" onClick={() => this.setState({ create: 1 })}
                                > <i class="material-icons text-white position-relative text-md pe-2">add</i> Add New</a> */}
                                



                                {this.state.verifyModal ? <>
                                  <Modal
                                    isOpen={this.state.verifyModal}
                                    onRequestClose={() => this.setState({ verifyModal: false })}
                                    className="custom-modal"
                                    overlayClassName="custom-modal-overlay"
                                  >
                                    <center>
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div class=" mx-4 input-group-outline my-2">
                                            Status
                                          </div>
                                          <div class=" mx-4 input-group-outline my-2">
                                            <select value={this.state.status} name="status" class="form-control" onChange={(e) =>
                                              this.handleInputChange(e)
                                            } >
                                              <option value="">Select</option>
                                              <option value="APPROVED">APPROVED</option>
                                              <option value="REJECT">REJECT</option>
                                            </select>
                                          </div>
                                        </div>

                                      </div>
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div class=" mx-4 input-group-outline my-2">
                                            Remarks
                                          </div>
                                          <div class=" mx-4 input-group-outline my-2">
                                            <textarea class="form-control" name="remarks" onChange={(e) =>
                                              this.handleInputChange(e)
                                            } >{this.state.remarks}</textarea>
                                          </div>
                                        </div>

                                      </div>
                                      {this.state.status == 'REJECT' ?
                                        <div class="row">
                                          <div class="col-sm-12">
                                            <div class=" mx-4 input-group-outline my-2">
                                              Reason
                                            </div>
                                            <div class=" mx-4 input-group-outline my-2">
                                              <select value={this.state.reason} name="reason" class="form-control" onChange={(e) =>
                                                this.handleInputChange(e)
                                              } >
                                                <option value="">Select Reason</option><option value="Incorrect KYC Documents">Incorrect KYC Documents</option><option value="Not a Genuine Plumber">Not a Genuine Plumber</option><option value="Duplicate Plumber">Duplicate Plumber</option><option value="other">Other</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div> : ''}
                                      <div class="row">
                                        <div class="col-sm-12">  <a href="#!" class="btn bg-gradient-default mb-2" onClick={() => verifyModal(this.state.memberId)}
                                        >  Cancel</a>&nbsp;
                                          <a href="#!" class="btn bg-gradient-primary mb-2" onClick={() => approveMember(this.state.memberId)}
                                          >  Save Status</a>
                                        </div>
                                      </div>
                                    </center>

                                  </Modal>
                                </>
                                  : <></>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-body px-0 pb-0">
                          <div class="table-responsive">
                            <table class="table table-flush" id="products-list">
                              <thead class="thead-light">
                                <tr>
                                  <th>Plumber Name</th>
                                  <th>Plumber Mobile</th>
                                  <th>Retailer Name</th>
                                  <th>Retailer Mobile</th>
                                  <th>TSE Name</th>
                                  <th>TSE Mobile</th>
                                  <th>Work Pincode</th>
                                  <th>Work City</th>
                                  <th>Work District</th>
                                  <th>Work State</th>
                                  <th>Invoice Image</th>
                                  <th>Status</th>
                                  <th>Pending Invoice Day </th>
                                  <th>Invoice Date </th>
                                  <th>Created On</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.members.map((item, i) => (
                                <tr key={item.id}> 
                                  <td>{item.name}</td>
                                  <td><p>{item.mobile}</p></td>
                                  <td><p>{item.retailerFirmName}</p></td>
                                  <td><p>{item.retailerMobile}</p></td>
                                  <td><p>{item.user?.parentName}</p></td>
                                  <td><p>{item.user?.parentMobile}</p></td>
                                  <td><p>{item.user?.addressWork?.workPincode}</p></td>
                                  <td><p>{item.user?.addressWork?.workCity}</p></td>
                                  <td><p>{item.user?.addressWork?.workdistrict}</p></td>
                                  <td><p>{item.user?.addressWork?.workState}</p></td>
                                  <td>
                                    <div className="invoice-images">
                                      {item.invoiceUrl1 && <img src={item.invoiceUrl1} height="75px" alt="Invoice 1" className="invoice-image" onClick={() => openImage(item.invoiceUrl1)} />}
                                      {item.invoiceUrl2 && <img src={item.invoiceUrl2} height="75px" alt="Invoice 2" className="invoice-image" onClick={() => openImage(item.invoiceUrl2)} />}
                                    </div>
                                    <div className="invoice-images">
                                      {item.invoiceUrl3 && <img src={item.invoiceUrl3} height="75px" alt="Invoice 3" className="invoice-image" onClick={() => openImage(item.invoiceUrl3)} />}
                                      {item.invoiceUrl4 && <img src={item.invoiceUrl4} height="75px" alt="Invoice 4" className="invoice-image" onClick={() => openImage(item.invoiceUrl4)}/>}
                                    </div>
                                  </td>
                                  <td class="date_two"><p>{item.status}</p></td>
                                  <td><p>{item.pendingInvoiceDay}</p></td>
                                  <td class="date_two">{item.invoiceDate}</td>
                                  <td class="date_two">{item.createDateTime}</td>
                                  {/* <td>
                                    {item.status === 'VERIFIED' &&
                                      <button className="btn btn-outline-primary btn-sm" onClick={() => verifyModal(item.id)}>
                                        <i className="material-icons text-lg">check_circle</i> Verify
                                      </button>
                                    }
                                  </td> */}
                                </tr>
                              ))}

                              </tbody>

                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </main>
              <div className="pagination-container">
                <Pagination
                  activePage={this.state.page}
                  itemsCountPerPage={this.state.limit}
                  totalItemsCount={this.state.totalResults}
                  pageRangeDisplayed={20}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>

            </>
          ) : this.state.resp ? (

            <CreateProgramInvoices resp={this.state.resp} setViewMode={this.handleViewMode} />
          ) : (
            <CreateProgramInvoices setViewMode={this.handleViewMode} />
          )
        }

      </>
    );
  }
}

export default ProgramInvoices;
