import React, { Component } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
// import SimpleReactValidator from "simple-react-validator";
// import Switch from "react-switch";
// import ReactDragListView from "react-drag-listview";

class CreateOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : '',
      email: this.props.resp ? this.props.resp.data.output.email : '',
      mobile: this.props.resp ? this.props.resp.data.output.mobile : '',
      password: this.props.resp ? this.props.resp.data.output.password : '',
      mobileWhatsApp: this.props.resp ? this.props.resp.data.output.mobileWhatsApp : '',
      designation: this.props.resp ? this.props.resp.data.output.designation : '',
      doa: this.props.resp ? this.props.resp.data.output.doa : '',
      marital: this.props.resp ? this.props.resp.data.output.marital : '',
      sex: this.props.resp ? this.props.resp.data.output.sex : '',
      parentId: this.props.resp ? this.props.resp.data.output.parentId : '',
      userType: 'SALES',

      workAddress1: this.props.resp && this.props.resp.data.output.addressWork ? this.props.resp.data.output.addressWork.workAddress1 : '',
      workAddress2: this.props.resp && this.props.resp.data.output.addressWork ? this.props.resp.data.output.addressWork.workAddress2 : '',
      workAddress3: this.props.resp && this.props.resp.data.output.addressWork ? this.props.resp.data.output.addressWork.workAddress3 : '',
      workCity: this.props.resp && this.props.resp.data.output.addressWork ? this.props.resp.data.output.addressWork.workCity : '',
      workState: this.props.resp && this.props.resp.data.output.addressWork ? this.props.resp.data.output.addressWork.workState : '',
      workPincode: this.props.resp && this.props.resp.data.output.addressWork ? this.props.resp.data.output.addressWork.workPincode : '',
      workCountry: this.props.resp && this.props.resp.data.output.addressWork ? this.props.resp.data.output.addressWork.workCountry : '',

      // userModelKYC: {
      //   aadharMobile: this.props.resp ? this.props.resp.data.output.userModelKYC.aadharMobile : '',
      //   aadharNumber: this.props.resp ? this.props.resp.data.output.userModelKYC.aadharNumber : '',
      //   aadharFrontUrl: this.props.resp ? this.props.resp.data.output.userModelKYC.aadharFrontUrl : '',
      //   aadharBackUrl: this.props.resp ? this.props.resp.data.output.userModelKYC.aadharBackUrl : '',
      //   aadharVerify: 0,
      //   aadharVerifiedBy: this.props.resp ? this.props.resp.data.output.userModelKYC.aadharVerifiedBy : '',
      //   aadharVerifiedOn: this.props.resp ? this.props.resp.data.output.userModelKYC.aadharVerifiedOn : '',
      //   panNumber: this.props.resp ? this.props.resp.data.output.userModelKYC.panNumber : '',
      //   panFrontUrl: this.props.resp ? this.props.resp.data.output.userModelKYC.panFrontUrl : '',
      //   panVerify: 0,
      //   panVerifiedBy: this.props.resp ? this.props.resp.data.output.userModelKYC.panVerifiedBy : '',
      //   panVerifiedOn: this.props.resp ? this.props.resp.data.output.userModelKYC.panVerifiedOn : '',
      // },
      // addressWork: {
      //   workaddress1: this.props.resp ? this.props.resp.data.output.addressWork.workaddress1 : '',
      //   workaddress2: this.props.resp ? this.props.resp.data.output.addressWork.workaddress2 : '',
      //   workaddress3: this.props.resp ? this.props.resp.data.output.addressWork.workaddress3 : '',
      //   workcity: this.props.resp ? this.props.resp.data.output.addressWork.workcity : '',
      //   workstate: this.props.resp ? this.props.resp.data.output.addressWork.state : '',
      //   pincode: this.props.resp ? this.props.resp.data.output.addressWork.pincode : '',
      //   country: this.props.resp ? this.props.resp.data.output.addressWork.country : '',
      // },
      // addressPermanent: {
      //   workaddress1: this.props.resp ? this.props.resp.data.output.addressPermanent.workaddress1 : '',
      //   workaddress2: this.props.resp ? this.props.resp.data.output.addressPermanent.workaddress2 : '',
      //   workaddress3: this.props.resp ? this.props.resp.data.output.addressPermanent.workaddress3 : '',
      //   workcity: this.props.resp ? this.props.resp.data.output.addressPermanent.workcity : '',
      //   workstate: this.props.resp ? this.props.resp.data.output.addressPermanent.state : '',
      //   pincode: this.props.resp ? this.props.resp.data.output.addressPermanent.pincode : '',
      //   country: this.props.resp ? this.props.resp.data.output.addressPermanent.country : '',
      // },
      parents: [],
      limit: 100000,
      totalResults: 0,
      sortBy: 'name',
      search: '',
      page: 1
    };
    this.validator = new SimpleReactValidator()
  }
  componentDidMount() {
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let programid = parts.pop();
    programid = parseInt(programid);
    const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
    let compid = pathSegments[pathSegments.length - 2];
    const SALESRequest = async () => {

      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "user/manage?" +
            "name=" +
            this.state.search +
            "&mobile=" +
            this.state.search +
            "&userType=SALES" +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "user/manage?sortBy=" +
            this.state.sortBy +
            "&userType=SALES" +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: {
              Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
              platform: Constants.pt
            },

          })
          .then((resp) => {
            //  SALESs array

            if (resp.status == 201 && resp.data.result != "error") {
              console.log(resp.data.output.results);
              this.setState({
                parents: resp.data.output.results,
              });
              console.log(this.state.members);
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    SALESRequest();
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleNestedInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    const group = target.dataset.group;

    this.setState((prevState) => ({
      ...prevState,
      [group]: {
        ...prevState[group],
        [name]: value,
      },
    }));
  };
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };


  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2];
      if (this.props.resp) {
        // Get the last second segment

        try {

          axios
            .patch(
              Constants.api + "user/manage/" + this.props.resp.data.output.id,
              {
                "name": this.state.name,
                "email": this.state.email,
                "mobile": this.state.mobile,
                "password": this.state.password,
                "designation": this.state.designation,
                // "dob": this.state.dob,
                // "doa": this.state.doa,
                // "marital": this.state.marital,
                // "sex": this.state.sex,
                "userType": "SALES",
                "parentId": this.state.parentId,
                "addressWork": {
                  "workAddress1": this.state.workAddress1,
                  "workAddress2": this.state.workAddress2,
                  "workAddress3": this.state.workAddress3,
                  "workCity": this.state.workcity,
                  "workState": this.state.workState,
                  "workPincode": this.state.workPincode,
                  "workCountry": this.state.workCountry
                }
              }
              ,

              {
                headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                swal("Manager Updated");
              }  else if (res.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
                // this.setState({alert:res.data.msg});
                swal("Something went wrong!");
              }
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
      else {
        // Get the last second segment

        try {
          axios
            .post(
              Constants.api + "user/manage",
              {
                "name": this.state.name,
                "email": this.state.email,
                "mobile": this.state.mobile,
                "password": this.state.password,
                "designation": this.state.designation,
                "parentId": this.state.parentId,

                // "dob": this.state.dob,
                // "doa": this.state.doa,
                // "marital": this.state.marital,
                // "sex": this.state.sex,
                "userType": "SALES",

                "addressWork": {
                  "workAddress1": this.state.workAddress1,
                  "workAddress2": this.state.workAddress2,
                  "workAddress3": this.state.workAddress3,
                  "workCity": this.state.workCity,
                  "workState": this.state.workState,
                  "workPincode": this.state.workPincode,
                  "workCountry": this.state.workCountry

                }
              },

              {
                headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
              }
            )
            .then((res) => {
              if (res.status === 201) {
                swal("Manager Added");
                this.setState({ create: 0 })
              }  else if (res.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
                // this.setState({alert:res.data.msg});
                swal("Something went wrong!");
              }
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {

    if (this.state.create === 0) {
      window.location.reload()
    }



    //  let search = this.state.search;

    return (
      <>

        <main>
          <div class="container-fluid my-3 py-3">
            <div class="row mb-5">

              <div class="col-lg-12 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-auto col-4">

                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                          Program Manager
                        </h5>

                      </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                      {/* <label class="form-check-label mb-0">
                        <small id="profileVisibility">
                          Switch to invisible
                        </small>
                      </label>
                      <div class="form-check form-switch ms-2 my-auto">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked onchange="visible()" />
                      </div> */}
                    </div>
                  </div>
                </div>
                <form >
                  <div class="card mt-4" id="basic-info">
                    <div class="row">
                      <div class="col-6">
                        <div class=" mx-4 input-group-outline my-2">
                          Name
                        </div>
                        <div class=" mx-4 input-group-outline my-2">
                          <input type="text" maxlength="30" name="name" value={this.state.name} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class=" mx-4 input-group-outline my-2">
                          Mobile
                        </div>
                        <div class=" mx-4 input-group-outline my-2">
                          <input type="text" maxlength="30" name="mobile" value={this.state.mobile} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>


                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class=" mx-4 input-group-outline my-2">
                          Email
                        </div>
                        <div class=" mx-4 input-group-outline my-2">
                          <input type="text" maxlength="30" name="email" value={this.state.email} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class=" mx-4 input-group-outline my-2">
                          Designation
                        </div>
                        <div class="i  mx-4 input-group-outline my-2">
                          <input type="text" maxlength="30" name="designation" value={this.state.designation} class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } />
                        </div>
                      </div>


                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class=" mx-4 input-group-outline my-2">
                          Parent
                        </div>
                        <div class=" mx-4 input-group-outline my-2">
                          <select value={this.state.parentId} name="parentId" class="form-control" onChange={(e) =>
                            this.handleInputChange(e)
                          } >
                            <option value="">Select</option>
                            {this.state.parents.map(function (item, i) {
                              return (
                                <>
                                  <option value={item.id}>{item.name}</option>
                                </>
                              )
                            })}
                          </select>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div class="card mt-4" id="basic-info">

                    <div class="card-body pt-2 ">
                      <section class="blog-area pt182">
                        <div class="row">
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="all-single-blog">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="card mt-4" id="password">
                                    <div class="card-header">
                                      <h5>Address</h5>
                                    </div>
                                    <div class="card-body pt-0">

                                      <div class="row">

                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          Address Line 1
                                        </div>
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          <input type="text" maxlength="30" name="workAddress1" value={this.state.workAddress1} class="form-control" onChange={(e) =>
                                            this.handleInputChange(e)
                                          } />
                                        </div>

                                      </div>

                                      <div class="row">

                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          Address Line 2

                                        </div>
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          <input type="text" maxlength="30" name="workAddress2" value={this.state.workAddress2} class="form-control" onChange={(e) =>
                                            this.handleInputChange(e)
                                          } />
                                        </div>

                                      </div>

                                      <div class="row">

                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          Address Line 3
                                        </div>
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          <input type="text" maxlength="30" name="workAddress3" value={this.state.workAddress3} class="form-control" onChange={(e) =>
                                            this.handleInputChange(e)
                                          } />
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          City
                                        </div>
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          <input type="text" name="workcity" value={this.state.workCity} class="form-control" onChange={(e) =>
                                            this.handleInputChange(e)
                                          } />
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          State
                                        </div>
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          <input type="text" name="workState" value={this.state.workState} class="form-control" onChange={(e) =>
                                            this.handleInputChange(e)
                                          } />
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          Pincode
                                        </div>
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          <input type="text" name="workPincode" value={this.state.workPincode} class="form-control" onChange={(e) =>
                                            this.handleInputChange(e)
                                          } />
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          Country
                                        </div>
                                        <div class="input-group  col-sm-4 input-group-outline my-4">
                                          <input type="text" name="workCountry" value={this.state.workCountry} class="form-control" onChange={(e) =>
                                            this.handleInputChange(e)
                                          } />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>
                      </section>

                    </div>
                  </div>


                  <div class="card mt-4" id="delete">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-sm-0 mb-4">
                        <div class="w-50">
                          <h5>Save Manager</h5>
                        </div>
                        <div class="w-50 text-end">
                          <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.setState({ create: 0 })}>Cancel</button>
                          <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" onClick={this.handleFormSubmit} >Save </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>


          </div>
        </main >
      </>
    );
  }

}

export default CreateOrders;
